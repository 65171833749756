import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Tbody extends Component {

	render() {

		return (
			<tbody className={this.props.refreshing ? 'semi-transparent' : undefined}>
				{this.props.children}
			</tbody>
		);
	}
}

Tbody.propTypes = {
	refreshing: PropTypes.bool,
};

Tbody.displayName = 'Tbody';

export default Tbody;
