import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Chart from "react-apexcharts";
import { requestData } from "core/ducks/list";
import moment from "moment";
import { Loading, Error } from 'core/components';
import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { OSM, Vector as VectorSource } from 'ol/source';
import { defaults as defaultControls } from 'ol/control';
import { fromLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Stroke, Fill, Text, Style } from 'ol/style';
import { SearchStation } from "../modals"
import { buildPath } from "core/model/lib/urlTools";
import { DynamicRoutes } from "../../model/routes";
import { WindRose } from "@eunchurn/react-windrose-chart";
import TooltipInfo from "../../components/tooltipInfo";
class SensorDataAnalysis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showGeneralStatistics: true,
            stream: null,
            from: null,
            to: null,
            min: null,
            max: null,
            info: false,
            general: false,
            movingAverage: false,
            movingAverageWindow: '1 week',
            movingAverageWindowOptions: ['6 hours', '8 hours', '1 day', '1 week'],
            showAdvancedStatistics: false,
            dependant: null,

            statsGeneral: false,

            histogramOptionsMin: 0.1,
            histogramOptionsMax: 1,
            histogramOptionsBins: 10,
            histogram: false,

            statsDetermination: false,
            statsRegression: false,

            roseKey: `roseKey-${Math.random()}`,
            roseHeight: 400,
            roseWidth: 400
        };

        this.getInfo = this.getInfo.bind(this);
        this.getStatistics = this.getStatistics.bind(this);
        this.updateMovingAverage = this.updateMovingAverage.bind(this);
        this.updateHistogram = this.updateHistogram.bind(this);
        this.updateLinearRegression = this.updateLinearRegression.bind(this);
        this.updateMap = this.updateMap.bind(this);
        this.searchStream = this.searchStream.bind(this);
        this.updateRoseDimensions = this.updateRoseDimensions.bind(this)

        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM()
                })
            ],
            controls: defaultControls({ attribution: true }).extend([]),
            view: new View({
                center: fromLonLat([23, 39]),
                zoom: 6
            })
        });


        this.roseDivRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.match.params.stream) {
            this.getInfo()
        }
        window.addEventListener('resize', this.updateRoseDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.match.params.stream === undefined && prevProps.match.params.stream !== undefined) {
            this.setState({
                showGeneralStatistics: true,
                stream: null,
                from: null,
                to: null,
                min: null,
                max: null,
                info: false,
                general: false,
                movingAverage: false,
                showAdvancedStatistics: false,
                dependant: null,
                statsGeneral: false,
                histogram: false,
                statsDetermination: false,
                statsRegression: false
            })

        } else if (this.props.info.status === 200) {

            if ((prevProps.info.pending && !this.props.info.pending) || (prevProps.info.refreshing && !this.props.info.refreshing && this.props.info.status === 200)) {
                this.setState({
                    info: this.props.info.data,
                    dependant: this.props.info.data.properties[0]
                })
            }

            if ((prevProps.general.pending && !this.props.general.pending) || (prevProps.general.refreshing && !this.props.general.refreshing && this.props.general.status === 200)) {
                
                this.props.general.data.series.forEach((s)=>{
                    s.data.forEach((e, i)=>{
                        try{
                            const d1 = new Date(e[0]);
                            const d2 = new Date(s.data[i + 1][0]);
                            const diff = d2.getTime() - d1.getTime();
                        
                            if( diff > 86400000){
                                
                                const j = diff /  86400000;
                                
                                for (let i = 1; i < j; i++) {
                                    const newDateObj = new Date(d1.getTime() + i * 86400000);
                                    s.data.push([newDateObj.toISOString().slice(0, 10), null])
                                  }
                            }
                        }catch(err){}
                    })
                    s.data.sort((a,b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0));
                })

                this.setState({
                    general: this.props.general.data,
                    from: this.props.general.data.general.from.split(" ")[0],
                    to: this.props.general.data.general.to.split(" ")[0],
                    min: this.props.general.data.general.from.split(" ")[0],
                    max: this.props.general.data.general.to.split(" ")[0]
                }, () => { this.updateMap() })
            }

            if ((prevProps.moving_average.pending && !this.props.moving_average.pending) || (prevProps.moving_average.refreshing && !this.props.moving_average.refreshing && this.props.moving_average.status === 200)) {
                
                this.props.moving_average.data.forEach((e, i)=>{
                    try{
                        const d1 = new Date(e[0]);
                        const d2 = new Date(this.props.moving_average.data[i + 1][0]);
                        const diff = d2.getTime() - d1.getTime();
                    
                        if( diff > 86400000 && diff !== 604800000){
                            
                            const j = diff /  86400000;
                            
                            for (let i = 1; i < j; i++) {
                                const newDateObj = new Date(d1.getTime() + i * 86400000);
                                this.props.moving_average.data.push([newDateObj.toISOString().slice(0, 10), null])
                              }
                        }
                    }catch(err){}
                })
                this.props.moving_average.data.sort((a,b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0));
                
                this.setState({
                    movingAverage: {
                        series: [{
                            name: "",
                            data: this.props.moving_average.data
                        }]
                    }
                })
            }

            if ((prevProps.statsGeneral.pending && !this.props.statsGeneral.pending) || (prevProps.statsGeneral.refreshing && !this.props.statsGeneral.refreshing && this.props.statsGeneral.status === 200)) {
                
                console.log(this.props.statsGeneral.data.series)

                this.props.statsGeneral.data.series.forEach((e, i)=>{
                    try{
                        const d1 = new Date(e[0]);
                        const d2 = new Date(this.props.statsGeneral.data.series[i + 1][0]);
                        const diff = d2.getTime() - d1.getTime();
                    
                        if( diff > 86400000 ){
                            
                            const j = diff /  86400000;
                            
                            for (let i = 1; i < j; i++) {
                                const newDateObj = new Date(d1.getTime() + i * 86400000);
                                this.props.statsGeneral.data.series.push([newDateObj.toISOString().slice(0, 10), null])
                              }
                        }
                    }catch(err){}
                })
                this.props.statsGeneral.data.series.sort((a,b) => (a[0] > b[0]) ? 1 : ((b[0] > a[0]) ? -1 : 0));


                this.setState({
                    statsGeneral: this.props.statsGeneral.data,
                    histogramOptionsMin: this.props.statsGeneral.data.min,
                    histogramOptionsMax: this.props.statsGeneral.data.max
                }, () => this.updateRoseDimensions())
            }

            if ((prevProps.statsHistogram.pending && !this.props.statsHistogram.pending) || (prevProps.statsHistogram.refreshing && !this.props.statsHistogram.refreshing && this.props.statsHistogram.status === 200)) {
                this.setState({
                    histogram: {
                        "histogram": this.props.statsHistogram.data.histogram,
                        "bins": [0, ...this.props.statsHistogram.data.bins].map((e) => e.toFixed(2))
                    },
                })
            }

            if ((prevProps.statsDetermination.pending && !this.props.statsDetermination.pending) || (prevProps.statsDetermination.refreshing && !this.props.statsDetermination.refreshing && this.props.statsDetermination.status === 200)) {
                this.setState({
                    statsDetermination: this.props.statsDetermination.data
                })
            }

            if ((prevProps.statsRegression.pending && !this.props.statsRegression.pending) || (prevProps.statsRegression.refreshing && !this.props.statsRegression.refreshing && this.props.statsRegression.status === 200)) {

                const slope = Number(this.props.statsRegression.data.slope);
                const intercept = Number(this.props.statsRegression.data.intercept);

                let line = [];
                this.props.statsRegression.data.sample.forEach((e) => {
                    line.push({
                        x: e.x,
                        y: slope * e.x + intercept
                    })
                });

                this.setState({
                    statsRegression: this.props.statsRegression.data,
                    statsRegressionLine: line.sort((a, b) => (a.x > b.x) ? 1 : ((b.x > a.x) ? -1 : 0))
                })
            }
        }
    }

    componentWillUnmount() {
        this.map.setTarget(null);
        window.removeEventListener('resize', this.updateRoseDimensions);
    }

    getInfo() {
        this.setState({
            stream: this.props.match.params.stream,
        }, () => {
            this.props.dispatch(requestData('info', `info/token/${this.state.stream}`)).then((e) => {
                this.props.dispatch(requestData('general', `stats/stream/${this.state.stream}`));
                this.props.dispatch(requestData('moving_average', `stats/stream/${this.state.stream}/category/moving_average/window/${this.state.movingAverageWindow}`));
            });
        });
    }

    getStatistics() {
        this.props.dispatch(requestData('statsGeneral', `stats/stream/${this.state.stream}/category/general/from/${this.state.from}/to/${this.state.to}`));
        this.setState({
            showAdvancedStatistics: true,
            histogram: false,
            statsRegression: false,
            statsDetermination: false
        })
    }

    updateMovingAverage() {
        this.setState({
            movingAverage: false
        })
        this.props.dispatch(requestData('moving_average', `stats/stream/${this.state.stream}/category/moving_average/window/${this.state.movingAverageWindow}`));
    }

    updateHistogram() {
        this.props.dispatch(requestData('statsHistogram', `stats/stream/${this.state.stream}/category/histogram/min/${this.state.histogramOptionsMin}/max/${this.state.histogramOptionsMax}/bins/${this.state.histogramOptionsBins}/from/${this.state.from}/to/${this.state.to}`));
    }

    updateLinearRegression() {
        this.props.dispatch(requestData('statsDetermination', `stats/stream/${this.state.stream}/category/determination/from/${this.state.from}/to/${this.state.to}/dependant/${this.state.dependant}`));
        this.props.dispatch(requestData('statsRegression', `stats/stream/${this.state.stream}/category/regression/from/${this.state.from}/to/${this.state.to}/dependant/${this.state.dependant}`));
    }

    updateMap() {
        try {
            this.map.removeLayer(this.map.getLayers().getArray()[1]);
        } catch (err) { }
        this.map.setTarget(null);
        this.map.getView().setCenter(fromLonLat([this.state.info.station.lon, this.state.info.station.lat]));
        this.map.getView().setZoom(16);

        const stationPoint = new Point(fromLonLat([this.state.info.station.lon, this.state.info.station.lat]));
        const propertyValue = this.state.general.general.value ? this.state.general.general.value : null;
        const features = [
            new Feature({
                'geometry': stationPoint,
                'value': propertyValue
            })
        ];

        const vectorSource = new VectorSource({ features: features });
        let dash_caclc = 2 * Math.PI * Math.max(8, Math.min(vectorSource.getFeatures().length * 0.75, 20)) / 4;
        const dash = [0, dash_caclc, dash_caclc, dash_caclc, dash_caclc, dash_caclc, dash_caclc];
        let setStyle = (feature) => {
            return new Style({
                image: new CircleStyle({
                    fill: new Fill({ color: [153, 1, 0, 1] }),
                    radius: 12,
                    stroke: new Stroke({
                        color: "rgba(153, 1, 0 ,0.7)",
                        width: 15,
                        lineDash: dash,
                        lineCap: "butt"
                    }),
                }),
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: '12px Calibri,sans-serif',
                    text: feature.get('value').toString(),
                    fill: new Fill({ color: '#fff' }),
                    placement: 'point',
                    overflow: 'true'
                })
            });
        };
        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: setStyle
        });
        this.map.addLayer(vectorLayer);
        this.map.setTarget("general-map-div");
    }

    searchStream(e) {
        if (e) {
            this.setState({
                stream: e,
                showAdvancedStatistics: false
            }, () => {
                this.props.history.push(buildPath(DynamicRoutes.DataAnalysis, [this.state.stream]));
                this.props.dispatch(requestData('info', `info/token/${this.state.stream}`)).then((e) => {
                    this.props.dispatch(requestData('general', `stats/stream/${this.state.stream}`));
                    this.props.dispatch(requestData('moving_average', `stats/stream/${this.state.stream}/category/moving_average/window/${this.state.movingAverageWindow}`));
                })
            })
        }
    }

    updateRoseDimensions() {
        if (this.state.statsGeneral.rose) {
            const w = this.roseDivRef.current.offsetWidth;
            this.setState({
                roseHeight: w,
                roseWidth: w,
                roseKey: `roseKey-${Math.random()}`
            })
        }
    }

    render() {

        const { status, errorMsg } = this.props.info;
        if (status !== 200 && status !== '') {
            return <Error status={status} errorMsg={errorMsg} />;
        }

        return (
            <div>
                <SearchStation searchStream={this.searchStream} info={this.state.info} />
                {
                    this.state.info && this.state.general ?
                        <Row>
                            <Col xs="12" sm="12" md="6">
                                <Card>
                                    <CardBody style={{ minHeight: '23em' }}>
                                        <CardTitle>
                                            <h4>{this.props.i18n['station']}: <span style={{ color: 'red' }}>{this.state.info.station.label}</span></h4>
                                            <h4>{this.props.i18n['property']}: <span style={{ color: 'red' }}>{this.state.info.property.symbol} ({this.state.info.unit.symbol})</span></h4>
                                            <h4>{this.props.i18n['period']}: <span style={{ color: 'red' }}>{moment(this.state.general.general.from).format('MM/DD/YYYY')} - {moment(this.state.general.general.to).format('MM/DD/YYYY')}</span></h4>
                                        </CardTitle>
                                        <hr />
                                        <CardTitle >
                                            <h5>{this.props.i18n['sensor']}</h5>
                                        </CardTitle>
                                        {this.state.info.sensor.description &&
                                            <CardSubtitle className="mb-2 text-muted">
                                                {
                                                    Object.keys(this.state.info.sensor.description).map((key, index) =>
                                                        <h6 key={`info-sensor-description-${key}`}>{key}: {this.state.info.sensor.description[key]}</h6>
                                                    )
                                                }
                                            </CardSubtitle>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="12" md="6">
                                <Card>
                                    <CardBody style={{ minHeight: '23em' }}>
                                        <div style={{ height: '20em' }} id='general-map-div'></div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        :
                        null
                }
                <hr style={{ background: '#E7B530' }} />
                {
                    this.state.info && this.state.general ?
                        <Row>
                            <Col xs="12" sm="12" md="4">
                                <Card>
                                    <CardBody style={{ minHeight: '26em' }}>
                                        <CardTitle tag="h5">{this.props.i18n['lastMeasurement']}</CardTitle>

                                        <CardTitle tag="h1"
                                            style={{ textAlign: 'center' }}>{this.state.general.general.value} {this.state.info.unit.symbol}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted"
                                            style={{ textAlign: 'center' }}>{moment(this.state.general.general.time).format('l LT')}</CardSubtitle>
                                        <hr />
                                        <CardTitle tag="h5">{this.props.i18n['meanLast']}</CardTitle>
                                        <div className="sensors popup-history">
                                            {
                                                Object.keys(this.state.general.average).map((key, index) =>
                                                    index === 0 || index % 4 !== 0 ?
                                                        <span key={`general-average-${key}`} className="legend-tooltip-popup"><div>{key}</div>{this.state.general.average[key]}</span>
                                                        :
                                                        [<br key={`general-average-br-${key}`} />, <span key={`general-average-${key}`} className="legend-tooltip-popup"><div>{key}</div>{this.state.general.average[key]}</span>]
                                                )
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <Card>
                                    <CardBody style={{ minHeight: '26em' }}>
                                        <CardTitle tag="h5">{this.props.i18n['meanNinMax']} <span id="tooltip-meanNinMax" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span></CardTitle>
                                        <TooltipInfo target={"tooltip-meanNinMax"} text={this.props.i18n['tooltip-meanNinMax']} />
                                        <Chart
                                            options={{
                                                chart: {
                                                    id: "chartMeanMinMax"
                                                },
                                                xaxis: {
                                                    type: 'datetime'
                                                },
                                                yaxis: {
                                                    forceNiceScale: true,
                                                    decimalsInFloat: 4,
                                                    title: {
                                                        text: `${this.state.info.property.symbol} (${this.state.info.unit.symbol})`,
                                                        offsetY: 10,
                                                        style: {
                                                            fontWeight: '600'
                                                        }
                                                    }
                                                },
                                                stroke: {
                                                    width: 2
                                                }
                                            }}
                                            series={[this.state.general.series[0], this.state.general.series[1], this.state.general.series[2]]}
                                            type="line"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs="12" sm="12" md="4">
                                <Card>
                                    <CardBody style={{ minHeight: '26em' }}>
                                        <CardTitle tag="h5">{this.props.i18n['movingAverage']} <span id="tooltip-movingAverage" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span></CardTitle>
                                        <TooltipInfo target={"tooltip-movingAverage"} text={this.props.i18n['tooltip-movingAverage']} />
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                                            <Form inline>
                                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                    <Label className="mr-sm-2">{this.props.i18n['selectAverageWindow']}</Label>
                                                    <Input type="select"
                                                        name="movingAverageVariable"
                                                        id="movingAverageVariable"
                                                        defaultValue={this.state.movingAverageWindow}
                                                        onChange={(e) => {
                                                            this.setState({
                                                                movingAverageWindow: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            this.state.movingAverageWindowOptions.map((option, index) => <option key={`moving-average-window-option-${index}`}>{option}</option>)
                                                        }
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                    <Button id="histogram-set-time-btn-0" onClick={this.updateMovingAverage} style={{
                                                        color: "white",
                                                        backgroundColor: "#B02B48",
                                                        borderColor: "#B02B48"
                                                    }}>{this.props.i18n['show']}
                                                    </Button>
                                                </FormGroup>
                                            </Form>
                                        </CardSubtitle>
                                        {
                                            this.state.movingAverage ?
                                                <Chart
                                                    options={{
                                                        chart: {
                                                            id: "movingAverage"
                                                        },
                                                        xaxis: {
                                                            type: 'datetime'
                                                        },
                                                        yaxis: {
                                                            forceNiceScale: true,
                                                            decimalsInFloat: 4,
                                                            title: {
                                                                text: `${this.state.info.property.symbol} (${this.state.info.unit.symbol})`,
                                                                offsetY: 10,
                                                                style: {
                                                                    fontWeight: '600'
                                                                }
                                                            }
                                                        },
                                                        stroke: {
                                                            width: 2
                                                        }
                                                    }}
                                                    series={this.state.movingAverage.series}
                                                    type="line"
                                                />
                                                :
                                                <Loading />
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        :
                        null
                }
                <hr style={{ background: '#E7B530' }} />
                {
                    this.state.general ?
                        <Row>
                            <Form inline style={{
                                padding: "0.5em",
                                margin: "auto auto 2em"
                            }}>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label tag="h5" for="fromDate"
                                        className="mr-sm-2">{this.props.i18n['generalRange']} {this.props.i18n['from date']}</Label>
                                    <Input type="date" name="fromDate" id="fromDate"
                                        value={this.state.from}
                                        min={this.state.min}
                                        max={this.state.to}
                                        onChange={(e) => this.setState({ from: e.target.value })}
                                        style={{ color: "black", border: "1px solid #B02B48" }} />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label tag="h5" for="toDate"
                                        className="mr-sm-2">{this.props.i18n['to date']}</Label>
                                    <Input type="date" name="toDate" id="toDate"
                                        value={this.state.to}
                                        min={this.state.from}
                                        max={this.state.max}
                                        onChange={(e) => this.setState({ to: e.target.value })}
                                        style={{ color: "black", border: "1px solid #B02B48" }} />
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Button id="timeFilter-set-time-btn" onClick={this.getStatistics} style={{
                                        color: "white",
                                        backgroundColor: "#B02B48",
                                        borderColor: "#B02B48"
                                    }}>{this.props.i18n['show']}</Button>
                                </FormGroup>
                            </Form>
                        </Row>
                        :
                        null
                }
                {
                    this.state.showAdvancedStatistics ?
                        <Row>

                            <Col xs="12" sm="12" md="4">
                                {
                                    this.state.statsGeneral ?
                                        <Card>
                                            <CardBody>
                                                <CardTitle tag="h5">{this.props.i18n['Descriptive Statistics']}: <span style={{ color: 'red' }}>{this.state.info.property.symbol} ({this.state.info.unit.symbol}) </span></CardTitle>
                                                <hr />
                                                <div className="sensors popup-history">
                                                    {
                                                        Object.keys(this.state.statsGeneral).map((key, index) =>
                                                            typeof this.state.statsGeneral[key] !== 'object' && key !== 'from' && key !== 'to'
                                                                ?
                                                                index !== 0 && index % 4 !== 0 ?
                                                                    <span id={`statsGeneral-${key}`} key={`statsGeneral-${key}`} className="legend-tooltip-popup" style={{ cursor: 'help' }}><TooltipInfo target={`statsGeneral-${key}`} text={this.props.i18n[`statsGeneral-${key}`]} /><div>{key}</div>{this.state.statsGeneral[key]}</span>
                                                                    :
                                                                    [<br key={`statsGeneral-br-${key}`} />, <span id={`statsGeneral-${key}`} key={`statsGeneral-${key}`} className="legend-tooltip-popup" style={{ cursor: 'help' }}><TooltipInfo target={`statsGeneral-${key}`} text={this.props.i18n[`statsGeneral-${key}`]} /><div>{key}</div>{this.state.statsGeneral[key]}</span>]
                                                                : null
                                                        )
                                                    }
                                                </div>
                                                <div className="sensors popup-history" id={`statsGeneral-quartiles`}>
                                                    <TooltipInfo target={`statsGeneral-quartiles`} text={this.props.i18n[`statsGeneral-quartiles`]} />
                                                    {
                                                        Object.keys(this.state.statsGeneral.quartiles).map((key, index) => {
                                                            return <span key={`statsGeneral-quartiles${key}`} className="legend-tooltip-popup" style={{ width: '8em', cursor: 'help' }}><div>{this.props.i18n['Quartiles']} {key}</div>{this.state.statsGeneral.quartiles[key]}</span>
                                                        })
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                        :
                                        <Loading />
                                }
                            </Col>

                            <Col xs="12" sm="12" md="4">
                                {
                                    this.state.statsGeneral ?
                                        <Card>
                                            <CardBody style={{ minHeight: '26em' }}>
                                                <CardTitle tag="h5">{this.props.i18n['mean']} <span id="tooltip-mean" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span></CardTitle>
                                                <TooltipInfo target={"tooltip-mean"} text={this.props.i18n['tooltip-mean']} />
                                                <Chart
                                                    options={{
                                                        chart: {
                                                            id: "chartGeneralSeries"
                                                        },
                                                        xaxis: {
                                                            type: 'datetime',
                                                            labels: {
                                                              datetimeUTC: false
                                                            }
                                                        },
                                                        yaxis: {
                                                            forceNiceScale: true,
                                                            decimalsInFloat: 4,
                                                            title: {
                                                                text: `${this.state.info.property.symbol} (${this.state.info.unit.symbol})`,
                                                                offsetY: 10,
                                                                style: {
                                                                    fontWeight: '600'
                                                                }
                                                            }
                                                        },
                                                        stroke: {
                                                            width: 2
                                                        },
                                                        tooltip: {
                                                            x: {
                                                                formatter: (value) => {
                                                                    const diff = moment(this.state.to).diff(moment(this.state.from), 'days');
                                                                    return diff < 4 ? moment(value).format('MM/DD/YYYY HH:mm') : moment(value).format('MM/DD/YYYY');
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    series={
                                                        [{
                                                            name: "",
                                                            data: this.state.statsGeneral.series
                                                        }]
                                                    }
                                                    type="line"
                                                />
                                            </CardBody>
                                        </Card>
                                        : null
                                }
                            </Col>

                            <Col xs="12" sm="12" md="4">
                                {
                                    this.state.statsGeneral ?
                                        <Card>
                                            <CardBody style={{ minHeight: '26em' }}>
                                                <CardTitle tag="h5">{this.props.i18n['hourlyMean']} <span id="tooltip-hourlyMean" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span></CardTitle>
                                                <TooltipInfo target={"tooltip-hourlyMean"} text={this.props.i18n['tooltip-hourlyMean']} />
                                                <Chart
                                                    options={{
                                                        chart: {
                                                            id: "chartGeneralMean",
                                                            animations: {
                                                                enabled: false
                                                            }
                                                        },
                                                        xaxis: {
                                                            categories: Object.entries(this.state.statsGeneral.hourly).map(v => v[0]),
                                                            labels: {
                                                                style: {
                                                                    fontSize: '10px'
                                                                },
                                                                offsetX: 0,
                                                            },
                                                            title: {
                                                                text: `${this.props.i18n['hoursOfDay']}`,
                                                                offsetY: -20,
                                                                style: {
                                                                    fontWeight: '600'
                                                                }
                                                            }
                                                        },
                                                        yaxis: {
                                                            forceNiceScale: true,
                                                            decimalsInFloat: 4,
                                                            title: {
                                                                text: `${this.state.info.property.symbol} (${this.state.info.unit.symbol})`,
                                                                offsetY: 10,
                                                                style: {
                                                                    fontWeight: '600'
                                                                }
                                                            }
                                                        },
                                                        tooltip: {
                                                            x: {
                                                                show: true,
                                                                formatter: (v) => {
                                                                    console.log(v)
                                                                    return `${this.props.i18n['from']} ${v - 1}:00 ${this.props.i18n['to']} ${v}:00`
                                                                },
                                                            }
                                                        },
                                                    }}
                                                    series={
                                                        [{
                                                            name: "",
                                                            data: Object.entries(this.state.statsGeneral.hourly).map((v, i) => [i + 1, v[1]])
                                                        }]
                                                    }
                                                    type="scatter"
                                                />
                                            </CardBody>
                                        </Card>
                                        : null
                                }
                            </Col>

                            <Col xs="12" sm="12" md="4">
                                {
                                    this.state.statsGeneral ?
                                        <Card>
                                            <CardBody>
                                                <CardTitle tag="h5">{this.props.i18n['histogram']} <span id="tooltip-histogram" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span></CardTitle>
                                                <TooltipInfo target={"tooltip-histogram"} text={this.props.i18n['tooltip-histogram']} />
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">
                                                    <Form inline>
                                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                            <Label className="mr-sm-2">min</Label>
                                                            <Input type="number" name="histogramMin" id="histogramMin"
                                                                min="0"
                                                                style={{ width: '5em' }}
                                                                value={this.state.histogramOptionsMin}
                                                                onChange={(e) => this.setState({ histogramOptionsMin: e.target.value })}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                            <Label className="mr-sm-2">max</Label>
                                                            <Input type="number" name="histogramMax" id="histogramMax"
                                                                min="0"
                                                                style={{ width: '5em' }}
                                                                value={this.state.histogramOptionsMax}
                                                                onChange={(e) => this.setState({ histogramOptionsMax: e.target.value })}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                            <Label className="mr-sm-2">bins</Label>
                                                            <Input type="number" name="histogramBins" id="histogramBins"
                                                                min="0"
                                                                style={{ width: '4em' }}
                                                                defaultValue={this.state.histogramOptionsBins}
                                                                onChange={(e) => this.setState({ histogramOptionsBins: e.target.value })}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                            <Button id="histogram-set-time-btn-1" onClick={this.updateHistogram} style={{
                                                                color: "white",
                                                                backgroundColor: "#B02B48",
                                                                borderColor: "#B02B48"
                                                            }}>{this.props.i18n['show']}</Button>
                                                        </FormGroup>
                                                    </Form>
                                                </CardSubtitle>
                                                {
                                                    this.state.histogram ?
                                                        <Chart
                                                            options={{
                                                                chart: {
                                                                    id: "chartHistogram"
                                                                },
                                                                xaxis: {
                                                                    categories: this.state.histogram.bins,
                                                                    labels: {
                                                                        offsetX: -8,
                                                                    },
                                                                    title: {
                                                                        text: `${this.state.info.property.symbol} (${this.state.info.unit.symbol})`,
                                                                        offsetY: 2,
                                                                        style: {
                                                                            fontWeight: '600'
                                                                        }
                                                                    }
                                                                },
                                                                tooltip: {
                                                                    enabled: false
                                                                },
                                                                dataLabels: {
                                                                    enabled: true,
                                                                    dropShadow: {
                                                                        enabled: true,
                                                                        left: 2,
                                                                        top: 2,
                                                                        opacity: 0.5
                                                                    }
                                                                },
                                                                stroke: {
                                                                    width: 2
                                                                }
                                                            }}
                                                            series={[{
                                                                name: "test",
                                                                data: this.state.histogram.histogram
                                                            }]}
                                                            type="bar"
                                                        />
                                                        : null
                                                }

                                            </CardBody>
                                        </Card>
                                        :
                                        <Loading />
                                }
                            </Col>


                            <Col xs="12" sm="12" md="4">
                                {
                                    this.state.statsGeneral ?
                                        <Card>
                                            <CardBody>
                                                <CardTitle tag="h5">{this.props.i18n['linearRegression']} - {this.props.i18n['correlation']} <span id="tooltip-linearRegression" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span></CardTitle>
                                                <TooltipInfo target={"tooltip-linearRegression"} text={this.props.i18n['tooltip-linearRegression']} />
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">
                                                    <Form inline>
                                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                            <Label
                                                                className="mr-sm-2">{this.props.i18n['selectSecondVariable']}</Label>
                                                            <Input type="select"
                                                                name="correlationVariable"
                                                                id="correlationVariable"
                                                                onChange={(e) => this.setState({ dependant: e.target.value })}
                                                            >
                                                                {
                                                                    this.state.info.properties.map((p, index) =>
                                                                        <option
                                                                            key={`corr-key-${index}`}>{p}</option>)
                                                                }
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                                            <Button id="histogram-set-time-btn-2" onClick={this.updateLinearRegression} style={{
                                                                color: "white",
                                                                backgroundColor: "#B02B48",
                                                                borderColor: "#B02B48"
                                                            }}>{this.props.i18n['show']}</Button>
                                                        </FormGroup>
                                                    </Form>
                                                </CardSubtitle>
                                                {
                                                    this.state.statsRegression && this.state.statsDetermination ?
                                                        <div>
                                                            <Chart
                                                                options={{
                                                                    chart: {
                                                                        id: "chartLinearRegression",
                                                                        type: 'line',
                                                                    },
                                                                    fill: {
                                                                        type: 'solid',
                                                                    },
                                                                    markers: {
                                                                        size: [6, 0]
                                                                    },
                                                                    tooltip: {
                                                                        shared: false,
                                                                        intersect: true,
                                                                    },
                                                                    legend: {
                                                                        show: false
                                                                    },
                                                                    xaxis: {
                                                                        type: 'numeric',
                                                                        title: {
                                                                            text: `${this.state.statsRegression.info.x.property} (${this.state.statsRegression.info.x.unit.symbol})`,
                                                                            offsetY: 2,
                                                                            style: {
                                                                                fontWeight: '600'
                                                                            }
                                                                        },
                                                                        labels: {
                                                                            formatter: function (value) {
                                                                                return parseFloat(value).toFixed(2);
                                                                            }
                                                                        }
                                                                    },
                                                                    yaxis: {
                                                                        title: {
                                                                            text: `${this.state.statsRegression.info.y.property} (${this.state.statsRegression.info.y.unit.symbol})`,
                                                                            style: {
                                                                                fontWeight: '600'
                                                                            }
                                                                        },
                                                                        decimalsInFloat: 2
                                                                    },
                                                                    stroke: {
                                                                        width: 2
                                                                    }
                                                                }}
                                                                series={[{
                                                                    name: this.state.statsRegression.info.y.property,
                                                                    type: 'scatter',
                                                                    data: this.state.statsRegression.sample
                                                                }, {
                                                                    name: ' ',
                                                                    type: 'line',
                                                                    data: this.state.statsRegressionLine
                                                                }]}
                                                            />
                                                            <p style={{ textAlign: "center" }}><span style={{
                                                                fontSize: "1.3125rem",
                                                                fontWeight: 500,
                                                                color: "red"
                                                            }}>y = {this.state.statsRegression.slope}x + {this.state.statsRegression.intercept}</span>
                                                            </p>
                                                            <p style={{ textAlign: "center" }}><span style={{
                                                                fontSize: "1.3125rem",
                                                                fontWeight: 500,
                                                                color: "red"
                                                            }}>R<sup>2</sup> = {this.state.statsDetermination['r-squared']}</span>
                                                            </p>
                                                            <p style={{ textAlign: "center" }}>{this.props.i18n['regressionSampleData']}</p>
                                                        </div>
                                                        : null
                                                }

                                            </CardBody>
                                        </Card>
                                        :
                                        <Loading />
                                }
                            </Col>

                            <Col xs="12" sm="12" md="4">
                                {
                                    this.state.statsGeneral.rose ?
                                        <Card>
                                            <CardBody>
                                                <CardTitle tag="h5">{this.props.i18n['roseDiagram']} <span id="tooltip-roseDiagram" style={{ cursor: 'help' }}><i className="fa fa-info-circle" /></span>: <span style={{ color: 'red' }}>{this.state.info.property.symbol} ({this.state.info.unit.symbol})</span></CardTitle>
                                                <TooltipInfo target={"tooltip-roseDiagram"} text={this.props.i18n['tooltip-roseDiagram']} />
                                                <div ref={this.roseDivRef}>
                                                    <WindRose key={this.state.roseKey} data={this.state.statsGeneral.rose.data} columns={this.state.statsGeneral.rose.columns} width={this.state.roseWidth} height={this.state.roseHeight} />
                                                </div>
                                            </CardBody>
                                        </Card> : null
                                }
                            </Col>

                        </Row>
                        : null
                }


            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages,

    info: state.list.info,
    general: state.list.general,
    moving_average: state.list.moving_average,

    statsGeneral: state.list.statsGeneral,
    statsHistogram: state.list.statsHistogram,
    statsDetermination: state.list.statsDetermination,
    statsRegression: state.list.statsRegression

});

SensorDataAnalysis = connect(mapStateToProps)(SensorDataAnalysis);

export default SensorDataAnalysis;
