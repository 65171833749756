import * as roles from 'core/model/roles';
const Settings = '/admin/settings';
const Things = Settings + '/things';
const Sensors = Settings + '/sensors';
const Properties = Settings + '/properties';
const Calibration = Settings + '/calibration';
let RawData = '/admin/raw-data';

export const StaticRoutes = {
	Settings,
	Calibration,
	Things,
	Sensors,
	Properties,
	RawData,
};

RawData = RawData + '/(station)?/:station([A-fa-f0-9]{32})?'

export const DynamicRoutes = {
	RawData,
};

const routes = [
	{
		url: Settings,
		name: 'settings',
		role: roles.ADMIN,
		to: Things,
		exact: true,
	},
	{
		url: Things,
		name: 'things',
		component: 'Things',
		contextComponent: 'ThingsSidebar',
		role: roles.EDITOR,
	},
	{
		url: Sensors,
		name: 'sensors',
		component: 'ObjectList',
		props: {scope: 'sensor'},
		role: roles.EDITOR,
	},
	{
		url: Properties,
		name: 'properties',
		component: 'ObjectList',
		props: {scope: 'property'},
		role: roles.EDITOR,
	},
	{
		url: RawData,
		name: 'raw-data',
		component: 'RawData',
		contextComponent: 'RawDataSidebar',
		exact: true,
		role: roles.REVIEWER,
	}
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
