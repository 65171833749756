import { StaticRoutes } from './model/routes';
import { StaticRoutes as CoreRoutes } from 'core/model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'map',
		icon: 'fa fa-map-o',
		url: StaticRoutes.SensorsMap,
		role: roles.GUEST,
	},
	{
		name: 'forecasts',
		icon: 'fa fa-globe',
		url: StaticRoutes.Cams,
		role: roles.GUEST,
	},
	{
		name: 'termsOfUse',
		icon: 'fa fa-rss',
		url: CoreRoutes.Policy,
		role: [roles.GUEST, roles.AUTHORIZED],
	},
	{
		name: 'map',
		icon: 'fa fa-map-o',
		url: StaticRoutes.SensorsMap,
		role: roles.AUTHORIZED,
	},
	{
		name: 'Data Analysis',
		icon: 'fa fa-bar-chart',
		url: StaticRoutes.DataAnalysis,
		role: roles.AUTHORIZED,
	},
	{
		name: 'forecasts',
		icon: 'fa fa-globe',
		url: StaticRoutes.Cams,
		role: roles.AUTHORIZED,
	},
];

export default menu;
