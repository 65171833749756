import React, { Component } from 'react';
import { connect } from 'react-redux';
import { requestData } from "core/ducks/list";
import { getData } from 'core/ducks/update';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { AsyncPaginate } from 'react-select-async-paginate';
import T from 'modules/i18n';

const customStyles = {
  label: {
    margin: '1em'
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2000,
  })
};
class SearchStation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      encTypes: [],
      ready: false,
      values: {},
      label: '',
      datastreams: [],
      datastream: '',
      init: true,
      paramKey: `param-key-${Math.random()}`
    };
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(
      getData('search/distinct/enc_type')
    ).then(r => {
      this.setState({
        encTypes: r,
        ready: true,
        values: r.reduce((obj, key) => ({
          ...obj,
          [key]: ''
        }), {})
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.info && this.state.init) {
      this.setState({
        init: false,
        label: {
          label: this.props.info.station.label,
          value: this.props.info.station.token
        },
        datastream: {
          value: this.props.info.token,
          label: this.props.info.property.symbol
        }
      });
    }

    if (!this.props.info && !this.state.init) {
      this.setState({
        init: true,
        label: '',
        datastream: ''
      });
    }

  }

  constructFilters = () => {
    const { values } = this.state;
    const filters = Object.keys(values)
      .filter(key => values[key] !== '')
      .map(key => `${key}:${values[key].value}`);
    return filters.join(';');
  }

  async loadPlace(encType, search, loadedOptions, { page }) {
    const url = search === '' ? `search/enc_type/${encType}/page/${page}` : `search/enc_type/${encType}/search/${search}/page/${page}`;
    const response = await this.props.dispatch(
      getData(url)
    );
    return {
      options: response.results.map(result => ({ value: result, label: result })),
      hasMore: response.hasMore,
      additional: {
        page: page + 1,
      },
    };
  }

  handleChange = (e, type) => {
    if (this.state.values[type] !== e)
      this.setState({
        values: {
          ...this.state.values,
          [type]: e || '',
        },
        label: '',
        datastreams: '',
        datastream: ''
      });
  }

  async loadStations(search, loadedOptions, { page }) {
    const filters = this.constructFilters();
    let url = ['search/field/label'];
    if (filters !== '')
      url.push(`filter/${filters}`);
    if (search !== '')
      url.push(`search/${search}`);
    url.push(`page/${page}`);
    url = url.join('/');
    const response = await this.props.dispatch(
      getData(url)
    );
    return {
      options: response.results,
      hasMore: response.hasMore,
      additional: {
        page: page + 1,
        filters: this.state.values,
      },
    };
  }

  handleStationChange = (label) => {
    this.setState({
      label: label || '',
      datastreams: '',
      datastream: '',
      paramKey: `param-key-${Math.random()}`
    }, () => {
      if (label){
        this.props.dispatch(requestData('searchInfo', `info/token/${this.state.label.value}`)).then((resutls) => {
          this.setState({
            datastreams: resutls.datastreams
          })
        })
      }     
    }
    );
  }

  async loadDataStreams(token, search, loadedOptions, { page }) {
    const url = `info/token/${token}`;
    const response = await this.props.dispatch(
      requestData(url)
    );
    if (response.datastreams) {
      return {
        options: response.datastreams.map(result => ({ value: result.token, label: result.property.symbol })),
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  }

  handleDataStreamChange = (datastream) => {
    this.setState({
      datastream: datastream
    })
  }

  search() {
    this.props.searchStream(this.state.datastream.value)
  }

  render() {
    return (
      <Card>
        <CardBody>
          <CardTitle tag="h5">{this.props.i18n['search']} {this.props.i18n['station']}</CardTitle>


          <FormGroup tag="fieldset" >
            <Row>
              {this.state.encTypes.map(type =>
                <Col key={`select_col_${type}`} style={{ paddingTop: 0 }}>
                  <FormGroup key={`select_${type}`} >
                    <Label className="text-primary" style={customStyles.label}>{type}</Label>
                    <AsyncPaginate
                      styles={customStyles}
                      name={type}
                      value={this.state.values[type]}
                      loadOptions={(search, loadedOptions, page) => this.loadPlace(type, search, loadedOptions, page)}
                      onChange={(e) => this.handleChange(e, type)}
                      isClearable={true}
                      additional={{
                        page: 1
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </FormGroup>

          <FormGroup>
            <Row>
              <Col style={{ paddingTop: 0 }}>
                <FormGroup>
                  <Label className="text-primary" style={customStyles.label}><T>station</T>*</Label>
                  <AsyncPaginate
                    styles={customStyles}
                    name="label_select"
                    key={JSON.stringify(this.state.values)}
                    value={this.state.label}
                    loadOptions={(search, loadedOptions, page) => this.loadStations(search, loadedOptions, page)}
                    onChange={(e) => this.handleStationChange(e)}
                    isClearable={true}
                    additional={{
                      page: 1
                    }}
                  />
                </FormGroup>
              </Col>
              <Col style={{ paddingTop: 0 }}>
                <FormGroup>
                  <Label className="text-primary" style={customStyles.label}><T>property</T>*</Label>
                  <AsyncPaginate
                    key={this.state.paramKey}
                    isDisabled={this.state.label['value'] ? false : true}
                    styles={customStyles}
                    name="datastream_select"
                    value={this.state.datastream}
                    loadOptions={(search, loadedOptions, page) => this.loadDataStreams(this.state.label.value, search, loadedOptions, page)}
                    onChange={(e) => this.handleDataStreamChange(e)}
                    isClearable={false}
                    additional={{
                      page: 1
                    }}
                  />
                </FormGroup>
              </Col>
              <Col style={{ paddingTop: 0 }}>
                <Button
                  style={{
                    position: 'absolute',
                    bottom: '1.5em',
                    color: "white",
                    backgroundColor: "#B02B48",
                    borderColor: "#B02B48"
                  }}
                  onClick={this.search}
                  disabled={this.state.datastream === '' ? true : false}
                >{this.props.i18n['show']}</Button>
              </Col>
            </Row>
          </FormGroup>


        </CardBody>
      </Card>
    )
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
  notifications: state.notifications.messages,
  i18n: state.i18n.messages
});

SearchStation = connect(mapStateToProps)(SearchStation);

export default SearchStation;