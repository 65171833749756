import React, { Component } from 'react';

class Tfoot extends Component {

	render() {

		let children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
		children = children.map((child, index) => {
			return React.cloneElement(child, {scope: 'tfoot', key: `child_${index}`});
		});

		return (
			<tfoot>
				{children}
			</tfoot>
		);
	}
}

Tfoot.displayName = 'Tfoot';

export default Tfoot;
