import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'PEC',
		icon: 'fa fa-circle-o-notch',
		url: StaticRoutes.Pec,
		role: roles.REVIEWER,
	},
];

export default menu;
