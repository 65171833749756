import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PlainText extends Component {

	render() {
		const { label } = this.props;
		return (
			<p className="font-weight-bold text-justify" dangerouslySetInnerHTML={{__html: label}}/>
		);
	}

}

PlainText.propTypes = {
	label: PropTypes.string.isRequired
};

export default PlainText;