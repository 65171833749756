import { flatten } from 'flat';

// Actions
const SET_LOCALE = 'locale/SET_LOCALE';
const LOAD_MESSAGES = 'locale/LOAD_MESSAGES';
const REQUEST_MESSAGES = 'locale/REQUEST_MESSAGES';

// Reducer
const initialState = {
	locale: '',
	messages: {},
	types: [],
	pending: false
};

export default (state=initialState, action) => {
	switch (action.type) {
		case SET_LOCALE:
			return {
				...state,
				locale: action.locale,
				types: action.types,
				pending: action.pending,
			}

		case LOAD_MESSAGES:
		case REQUEST_MESSAGES:
			return {
				...state,
				messages: {...state.messages, ...flatten(action.messages)},
				types: [...state.types, action.category],
				pending: action.pending
			}

		default:
			return state;
	}
}

// Action Creators
export const setLocale = (locale) => ({
	type: SET_LOCALE,
	locale,
	types: [],
	pending: true
});

const loadMessages = (locale, messages) => ({
	type: LOAD_MESSAGES,
	messages,
	locale,
	category: [],
	pending: false
});

export const fetchMessages = (locale) => (dispatch, getState) => {
	fetch(`/locales/general-${locale}.json`)
		.then(res => res.json())
		.then(messages => {
			dispatch(loadMessages(locale, messages));
		});
};

export const changeLocale = (locale) => (dispatch, getState) => {
	// let categories = getState().i18n.types;
	dispatch(setLocale(locale));
	dispatch(fetchMessages(locale));
	// dispatch(requestAdditionalMessages(categories));
};

export const requestAdditionalMessages = (categories) => (dispatch, getState) => {
	categories = Array.isArray(categories) ? categories : [categories];
	for (let index = 0; index < categories.length; index++) {
		let category = categories[index];
		let state = getState().i18n;
		if (state.types.includes(category))
			return;
		// let locale = state.locale;

	}
};
