import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';

import ProfileForm from 'core/views/forms/profileForm';
import Alert from 'core/views/modals/alert';
import { Prompt } from 'core/components';
import { toggleModal } from 'core/ducks/ui/modal';
import { unsavedData } from 'core/ducks/forms';
import T from 'modules/i18n';

class UsersAdd extends Component {

	constructor(props) {
		super(props);
		this.actions = bindActionCreators({toggleModal, unsavedData}, this.props.dispatch);
	}

	render() {
		return (
			<div className="animated fadeIn">
				<Row>
					<Col xs="12" lg="12">
						<Card>
							<CardHeader>
								<strong><T>add user</T></strong>
							</CardHeader>
							<CardBody>
								<ProfileForm unsubmitted={this.actions.unsavedData} />
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Prompt
					when={this.props.unsaved_data}
					action={(onConfirm) => {
						this.actions.toggleModal(true,
							<Alert
								onConfirm={onConfirm}
								toggle={this.actions.toggleModal}
								message="unsave_data_alert"
								title="confirm navigation"
							/>
						);
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	unsaved_data: state.forms.unsaved_data,
});

UsersAdd = connect(mapStateToProps)(UsersAdd);

export default UsersAdd;
