import React, { Component } from 'react';
import { Input, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Col } from 'reactstrap';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import T from 'modules/i18n';

class ServiceModal extends Component {

	constructor(props) {
		super(props);
		const values = this.parseValues(props.getValues());
		this.initialValues = {
			description: '',
			uri: '',
			parameters: [],
			period: '',
			format: 'json',
			datastream: props.datastreams.map(stream => {
				const { label, ...other } = stream;
				return {definition: '', created_field: '', ...other};
			}),
			...values,
		};
		this.state = {
			values: {...this.initialValues},
		}
	}

	parseValues = (values) => {
		if (!values) return {};
		values = Object.keys(values).reduce((obj, key) => ({
			...obj,
			[key]: values[key] || '',
		}), {});
		values.parameters = Object.keys(values.parameters)
			.map(key => ({key, value: values.parameters[key]}));
		let period = values.period.split(':');
		values.period = 0;
		const multiplier = [3600, 60, 1];
		period.forEach((elem, index) => {
			values.period += elem * multiplier[index];
		});
		return values;
	}

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: value,
			}
		});
	}

	handleParameterChange = (event, index) => {
		const { name, value } = event.target;
		const { parameters } = this.state.values;
		this.setState({
			values: {
				...this.state.values,
				parameters: [
					...parameters.slice(0, index),
					{...parameters[index], [name]: value},
					...parameters.slice(index + 1),
				]
			}
		});
	}

	handleStreamChange = (event, index) => {
		const { name, value } = event.target;
		const { datastream } = this.state.values;
		this.setState({
			values: {
				...this.state.values,
				datastream: [
					...datastream.slice(0, index),
					{...datastream[index], [name]: value},
					...datastream.slice(index + 1),
				]
			}
		});
	}

	addParameter = () => {
		this.setState({
			values: {
				...this.state.values,
				parameters: [...this.state.values.parameters, {key: '', value: ''}],
			}
		});
	}

	removeParameter = (index) => {
		const { parameters } = this.state.values;
		this.setState({
			values: {
				...this.state.values,
				parameters: [
					...parameters.slice(0, index),
					...parameters.slice(index + 1),
				]
			}
		});
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { parameters, ...data } = this.state.values;
		data.parameters = parameters.reduce((obj, param) => ({
			...obj,
			[param.key]: param.value,
		}), {});
		this.props.onSubmit(data);
	}

	render() {

		const { isOpen, toggle, datastreams } = this.props;
		const { values } = this.state;

		return (
			<Modal isOpen={isOpen} className="modal-lg">
				<ModalHeader toggle={toggle}><T>service</T></ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						<FormGroup>
							<Label htmlFor="description_input"><T>description</T></Label>
							<Input id="description_input" required name="description" value={values.description} onChange={this.handleChange}/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="uri_input">URI</Label>
							<Input id="uri_input" required name="uri" value={values.uri} onChange={this.handleChange}/>
						</FormGroup>
						<fieldset className="border-top border-bottom mb-2">
							<legend style={{fontSize: '120%', width: 'auto'}} className="ml-3 px-1"><T>parameters</T></legend>
							{ values.parameters.map((parameter, index) => (
								<FormGroup key={`param_row_${index}`} row>
									<Col xs="5" className="py-0">
										<Input
											placeholder="key"
											required
											name="key"
											value={parameter.key}
											onChange={(e) => this.handleParameterChange(e, index)}
										/>
									</Col>
									<Col xs="5" className="py-0">
										<Input
											placeholder="value"
											required
											name="value"
											value={parameter.value}
											onChange={(e) => this.handleParameterChange(e, index)}
										/>
									</Col>
									<Col xs="2" className="py-0">
										<i
											role="link"
											className="fa fa-minus-circle d-inline-block mx-auto text-warning"
											onClick={() => this.removeParameter(index)}
										/>
									</Col>
								</FormGroup>
							))}
							<i style={{fontSize: '140%'}} role="link" className="fa fa-plus-circle float-right mr-2" onClick={this.addParameter}/>
							<div className="clearfix"/>
						</fieldset>
						<FormGroup row>
							<Col sm="6" className="py-0">
								<FormGroup>
									<Label htmlFor="period_input"><T>period</T>{' '}(s)</Label>
									<Input type="number" id="period_input" required name="period" value={values.period} onChange={this.handleChange}/>
								</FormGroup>
							</Col>
							<Col sm="6" className="py-0">
								<FormGroup>
									<Label htmlFor="format_input"><T>format</T></Label>
									<Input type="select" name="format" value={values.format} onChange={this.handleChange}>
										<option value="json">json</option>
										<option value="csv">csv</option>
									</Input>
								</FormGroup>
							</Col>
						</FormGroup>
						<Table>
							<Thead>
								<Td>datastream</Td>
								<Td><T>definition</T></Td>
								<Td><T>created_field</T></Td>
							</Thead>
							<Tbody>
								{ datastreams.map((stream, index) => (
									<Tr key={`service_table_row_${stream.token}`}>
										<Td>{stream.label}</Td>
										<Td>
											<Input
												required
												name="definition"
												value={values.datastream[index].definition}
												onChange={(e) => this.handleStreamChange(e, index)}
											/>
										</Td>
										<Td>
											<Input
												required
												name="created_field"
												value={values.datastream[index].created_field}
												onChange={(e) => this.handleStreamChange(e, index)}
											/>
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
						<div className="text-right">
							<Button color="success" className="mr-2"><T>submit</T></Button>
							<Button color="warning" className="mr-2" onClick={this.closeModal}><T>cancel</T></Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}

}

export default ServiceModal;