import { menuReducers, menuInitialState } from 'modules';

// Actions
const TOGGLE_SIDEBAR = 'menu/TOGGLE_SIDEBAR';
const TOGGLE_MOBILE_SIDEBAR = 'menu/TOGGLE_MOBILE_SIDEBAR';
const MINIMIZE_SIDEBAR = 'menu/MINIMIZE_SIDEBAR';
const TOGGLE_ASIDE = 'menu/TOGGLE_ASIDE';
const TOGGLE_MOBILE_ASIDE = 'menu/TOGGLE_MOBILE_ASIDE';
const OPEN_ASIDE = 'menu/OPEN_ASIDE';
const CLOSE_ASIDE = 'menu/CLOSE_ASIDE';
const SET_ASIDE_STATE = 'aside/SET_STATE';
const CLEAR_ASIDE_STATE = 'aside/CLEAR_STATE'

// Reducer
const initialState = {
	sidebarOpen: true,
	sidebarMinimized: false,
	sidebarMobileShow: false,
	hideAsideMenu: true,
	hideAsideMobileMenu: true,
	asideState: {},
	...menuInitialState
};

export default (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
	  		return {
				...state,
				sidebarOpen: !state.sidebarOpen
			};

		case TOGGLE_MOBILE_SIDEBAR:
			return {
				...state,
				sidebarMobileShow: !state.sidebarMobileShow
			};

		case MINIMIZE_SIDEBAR:
			return {
				...state,
				sidebarMinimized: !state.sidebarMinimized
			}

		case TOGGLE_ASIDE:
			return {
				...state,
				hideAsideMenu: !state.hideAsideMenu
			}

		case TOGGLE_MOBILE_ASIDE:
			return {
				...state,
				hideAsideMobileMenu: !state.hideAsideMobileMenu
			}

		case OPEN_ASIDE:
			return {
				...state,
				hideAsideMenu: false
			}

		case CLOSE_ASIDE:
			return {
				...state,
				hideAsideMenu: true,
				hideAsideMobileMenu: true,
			}

		case SET_ASIDE_STATE:
			return {
				...state,
				asideState: {
					...state.asideState,
					...action.asideState
				}
			}

		case CLEAR_ASIDE_STATE:
			return {
				...state,
				asideState: {}
			}

		default:
			let moduleState;
			if (menuReducers)
				menuReducers.forEach(reducer => {
					if (!moduleState && typeof reducer === 'function')
						moduleState = reducer(state, action);
				});
			let finalState = moduleState
				? {...state, ...moduleState}
				: state;
	  		return finalState;
  	}
};

// Action creators
export const toggleSidebar = () => ({
	type: TOGGLE_SIDEBAR,
});

export const toggleMobileSidebar = () => ({
	type: TOGGLE_MOBILE_SIDEBAR,
});

export const minimizeSidebar = () => ({
	type: MINIMIZE_SIDEBAR,
});

export const toggleAside = () => ({
	type: TOGGLE_ASIDE,
});

export const toggleMobileAside = () => ({
	type: TOGGLE_MOBILE_ASIDE,
})

export const openAside = () => ({
	type: OPEN_ASIDE,
});

export const closeAside= () => ({
	type: CLOSE_ASIDE,
});

export const setAsideState = (asideState) => ({
	type: SET_ASIDE_STATE,
	asideState
});

export const clearAsideState = () => ({
	type: CLEAR_ASIDE_STATE,
});
