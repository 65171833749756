import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Col, Button,
} from 'reactstrap';
import { getValidation } from 'core/ducks/forms';
import { JsonInput } from 'input-fields';

import T from 'modules/i18n';

class ObjectModal extends Component {

	constructor(props) {
		super(props);
		this.intialValues = props.fields.reduce((obj, field) => ({
			...obj,
			[field]: props.data && props.data[field] ? props.data[field] : '',
		}), {});
		this.state = {
			values: {...this.intialValues},
			rules: null,
		};
	}

	componentDidMount() {
		this.props.dispatch(getValidation(this.props.scope));
	}

	componentDidUpdate(prevProps) {
		const { scope } = this.props;
		if (this.props.validation.scope === scope && prevProps.validation.pending && !this.props.validation.pending) {
			this.setState({rules: this.props.validation.rules})
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.onSubmit(this.state.values);
	}

	handleChange = (e) => {
		const { name, value, checked, type } = e.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: type==='checkbox' ? checked : value,
			}
		});
	}

	render() {
		const { isOpen, toggle, scope } = this.props;
		const { values, rules } = this.state;
		if (!rules)
			return null;

		return (
			<Modal isOpen={isOpen} className="modal-md">
				<ModalHeader toggle={toggle}><T>{scope}</T></ModalHeader>
				<ModalBody>
					<Form onSubmit={this.handleSubmit}>
						{ Object.keys(values).map(field => (
							<FormGroup key={`formgroup_${field}`} row>
								<Label sm="2" className="py-0"><T>{field}</T></Label>
								<Col className="py-0">
									{ rules[field].type !== 'json'
										? rules[field].type === 'boolean' ?
											<Input
												required={rules[field].required}
												name={field}
												type="checkbox"
												value={values[field]}
												checked={values[field]}
												onChange={this.handleChange}
											/>
											:
											<Input
												required={rules[field].required}
												minLength={rules[field].min_size}
												maxLength={rules[field].max_size}
												name={field}
												value={values[field]}
												onChange={this.handleChange}
											/>
										: <JsonInput name={field} value={values[field]} onChange={this.handleChange}/>
									}
								</Col>
							</FormGroup>
						))}
						<div className="text-right">
							<Button color="success" className="mr-2"><T>submit</T></Button>
							<Button color="warning" className="mr-2" onClick={toggle}><T>cancel</T></Button>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	validation: state.forms.validation,
});

ObjectModal = connect(mapStateToProps)(ObjectModal);

export default ObjectModal;
