import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

class Error extends Component {
	render() {
		return (
			<div className="app flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Col md="6">
							<div className="clearfix">
								<h1 className="float-left display-3 mr-4">{this.props.status}</h1>
								<h4 className="pt-3">Something went wrong:</h4>
								<p className="text-muted float-left"><em>{this.props.errorMsg}</em> Please try again later.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

Error.propTypes = {
	status: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]).isRequired,
	errorMsg: PropTypes.string
};

export default Error;