import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import { getData } from 'core/ducks/update';
import { buildPath, getParameters } from 'core/model/lib/urlTools';
import { DynamicRoutes, default as allRoutes } from '../../model/routes';
import Filters from '../forms/filters';
import T from 'modules/i18n';

class RawData extends Component {

	constructor(props) {
		super(props);
		this.state = {
			label: '',
		};
	}

	componentDidMount() {
		const params = getParameters(this.props.location.pathname, allRoutes.routes);
		if (params.station)
			this.props.dispatch(
				getData(`thing/token/${params.station}`)
			).then(r => {
				this.setState({label: {value: params.station, label: r.label}})
			});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.label !== this.state.label)
			this.props.history.push(buildPath(DynamicRoutes.RawData, [this.state.label.value]));
	}

	render() {

		const { label } = this.state;

		return (
			<>
				<Nav tabs>
					<NavItem>
						<NavLink className='active text-info p-2'>
							<T>station</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={0} className="scroller mx-2 mt-2">
					<TabPane tabId={0}>
						<Filters label={label} handleSelect={(label) => this.setState({label})} />
					</TabPane>
				</TabContent>
			</>
		);
	}

}

RawData = connect(null)(RawData);

export default RawData;
