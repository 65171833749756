import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Label, Col, Button,
} from 'reactstrap';

import { getData, updateData } from 'core/ducks/update';
import { Info, Edit, Service } from '../datastreams';
import T from 'modules/i18n';

const Content = (props) => {
	const { mode, ...otherProps} = props;
	switch (mode) {
		case 'info':
			return <Info {...otherProps}/>
		case 'edit':
			return <Edit {...otherProps}/>
		case 'service':
			return <Service {...otherProps}/>
		default:
			return null;
	}
};

class Datastreams extends Component {

	constructor(props) {
		super(props);
		this.modes = ['info', 'edit', 'service'];
		this.state = {
			ready: false,
			mode: 'info',
			data: [],
			newStation: props.newStation,
			isButtonEnabled: false,
		};
	}

	componentDidMount() {
		this.props.dispatch(
			getData(`datastream/thing/${this.props.thing}`)
		).then(data => {
			const newStation = (data.length === 0);
			this.setState({data, ready: true, newStation, mode: newStation ? 'edit' : 'info'})
		}).catch(err => console.warn(err));
	}

	handleModeSelection = (event) => {
		const { value } = event.target;
		if (this.state.mode !== value)
			this.setState({
				mode: value,
			});
	}

	handleForward = () => {
		const index = this.modes.indexOf(this.state.mode);
		this.props.dispatch(
			getData(`datastream/thing/${this.props.thing}`)
		).then(data => {
			this.setState({
				data,
				isButtonEnabled: true,
				mode: this.modes[index + 1]
			});
		});
	}

	handleCompletion = () => {
		this.props.dispatch(
			updateData(`thing/token/${this.props.thing}`, {is_active: true})
		).then(() => this.props.toggle());
	}

	render() {
		const { isOpen, toggle, thing } = this.props;
		const { ready, data, mode, newStation, isButtonEnabled } = this.state;

		if (!ready) return null;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Modal isOpen={isOpen} className="modal-xl" scrollable>
				<ModalHeader toggle={toggle}>Datastreams</ModalHeader>
				<ModalBody>
					<FormGroup className="float-right row">
						<Label htmlFor="mode_selection" className="pr-2 py-0 mt-2" sm={3}>mode:</Label>
						<Col sm={9} className="py-0">
							<Input disabled={newStation} id="mode_selection" type="select" value={mode} onChange={this.handleModeSelection}>
								{this.modes.map(mode => (
									<option key={`mode_${mode}`} value={mode}>{mode}</option>
								))}
							</Input>
						</Col>
					</FormGroup>
					<div className="clearfix"/>
					<Content mode={mode} data={data} thing={thing} messages={messages} toggleButton={(mode) => this.setState({isButtonEnabled: mode})}/>
				</ModalBody>
				{ newStation &&
					<ModalFooter className="text-right">
						{ mode !== 'service' ?
							<Button disabled={!isButtonEnabled} color="success" className="mr-2" onClick={this.handleForward}>
								<i className="fa fa-step-forward mr-2"/><T>next</T>
							</Button>
							:
							<Button disabled={!isButtonEnabled} color="success" className="mr-2" onClick={this.handleCompletion}>
								<i className="fa fa-check mr-2"/><T>complete and activation</T>
							</Button>
						}
					</ModalFooter>
				}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

Datastreams = connect(mapStateToProps)(Datastreams);

export default Datastreams;
