import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'reactstrap';

import { headerItems } from 'modules';
import HeaderDropdown from './headerDropdown';

class Header extends Component {

	render() {
		const className = this.props.className || 'ml-auto navbar';
		return (
			<Nav className={className}>
				<HeaderDropdown/>
				{ Object.values(headerItems).map((Item, index) => <Item key={`header_item_${index}`} minimal={this.props.minimal}/>) }
			</Nav>
		);
	}
}

Header.propTypes = {
	minimal: PropTypes.bool,
};

Header.defaultProps = {
	minimal: false,
};

export default Header;
