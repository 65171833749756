import React, { Component } from 'react';
import T from 'modules/i18n';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
		// console.error(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <h4 className="text-danger mt-4"><T>unexpected error</T></h4>;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
