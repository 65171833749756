import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Table, Form, Card, CardBody, FormText, FormGroup, Label, Input, Button, Badge, Container } from 'reactstrap';
import Tooltip from 'react-tooltip';
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';

import { requestData } from 'core/ducks/list';
import { updateData, deleteData } from 'core/ducks/update';
import T from 'modules/i18n';
import { Loading } from 'core/components';
import { toggleModal } from 'core/ducks/ui/modal';
import Alert from 'core/views/modals/alert';
import { initContext } from 'core/ducks/context';

import '../../scss/alert.scss';

class Alerts extends Component {

	constructor(props) {
		super(props);
		const values = {threshold: '', relation: '', duration: '', period: '', send_stop_email: ''};
		this.initialState = {
			selected: '',
			values: {...values},
			initialValues: {...values},
			needsRefresh: false,
		}
		this.state = {
			...this.initialState,
		};
		this.actions = bindActionCreators({toggleModal, deleteData}, this.props.dispatch);

		this.props.dispatch(initContext({needsRefresh: this.state.needsRefresh, set: (newContext) => this.setState({...newContext})}));

		this.handleItemEdit = this.handleItemEdit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSumit = this.handleFormSumit.bind(this);
	}

	componentDidMount() {
		this.requestData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.needsRefresh && this.state.needsRefresh)
			this.requestData();
	}

	requestData() {
		this.props.dispatch( requestData('alert') ).then(() => this.setState({needsRefresh: false}));
	}

	handleItemEdit(id) {
		if (id === this.state.selected) {
			this.setState({...this.initialState});
		} else {
			const values = this.props.list.alert.data[id];
			this.setState({
				selected: id,
				values,
				initialValues: {...values}
			});
		}
	}

	handleInputChange(event) {
		const { name, type, value, checked } = event.target;
		this.setState({
			values: {...this.state.values, [name]: type==='checkbox' ? checked : value}
		});
	}

	handleFormSumit(event) {
		event.preventDefault();
		const { dispatch } = this.props;
		const { values } = this.state;
		const keys = Object.keys(this.initialState.values);
		const data = keys.filter(key => values[key] !== this.state.initialValues[key]).reduce((obj, key) => ({
			...obj,
			[key]: values[key]
		}), {});
		if (Object.keys(data).length > 0) {
			dispatch( updateData(`alert/id/${this.state.selected}`, data) ).then(() => {
				this.requestData();
				this.setState({
					...this.initialState,
				});
			});
		}
	}

	render() {
		if (!this.props.list.alert || this.props.list.alert.pending)
			return <Loading/>;

		const alerts = this.props.list.alert.data;
		const { messages } = this.props.i18n || {messages: {}};
		const { selected } = this.state;

		const header = {station: 'str', property: 'str', relation: 'enum', threshold: 'float', unit: 'str', duration: 'int', status: 'enum'}

		const form = (
			<Card className="p-0 m-0">
				<CardBody>
					<Form inline onSubmit={this.handleFormSumit}>
						<Container>
							<Row>
								<Col>
									<FormGroup row tag="fieldset" className="border-top border-bottom pb-3">
										<legend style={{fontSize: 'initial', width: 'initial'}} className="px-2 ml-3"><T>condition</T></legend>
										<Badge className="p-2 mx-2">{this.state.values.property}</Badge>
										<Input className="mx-2" type="select" value={this.state.values.relation} name="relation" onChange={this.handleInputChange}>
											<option value="ABOVE">{messages.above.toUpperCase()}</option>
											<option value="BELOW">{messages.below.toUpperCase()}</option>
										</Input>
										<Input className="mx-2" style={{width: '5rem'}} type="number" value={this.state.values.threshold} name="threshold" onChange={this.handleInputChange}/>
										<FormText>({this.state.values.unit})</FormText>
										<Label className="mx-2" htmlFor="input_duration"><T>for</T></Label>
										<Input className="mx-2" style={{width: '5rem'}} id="input_duration" type="number" value={this.state.values.duration} name="duration" onChange={this.handleInputChange}/>
										<FormText>(s)</FormText>
										<span>.</span>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup row>
										<Label className="mx-2" htmlFor="input_period"><T>send emails when active every</T></Label>
										<Input className="mx-2" style={{width: '5rem'}} id="input_period" type="number" value={this.state.values.period} name="period" onChange={this.handleInputChange}/>
										<FormText>(hr)</FormText>
										<span>.</span>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col>
									<FormGroup row>
										<Label className="mx-2" htmlFor="input_send_stop_email"><T>send email when alert stops</T></Label>
										<Input className="mx-2" id="input_send_stop_email" type="checkbox" checked={this.state.values.send_stop_email} name="send_stop_email" onChange={this.handleInputChange}/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col className="text-right">
									<Button type="submit" className="m-1"><T>submit</T></Button>
									<Button type="button" className="m-1" color="warning" onClick={() => {this.setState({...this.initialState})}}><T>cancel</T></Button>
								</Col>
							</Row>
						</Container>
					</Form>
				</CardBody>
			</Card>
		);

		return (
			<Card className="mt-4">
				<CardBody>
					<Table responsive striped className="border">
						<thead>
							<tr>
								<th/>
								{ Object.keys(header).map(key => (
									key === 'duration'
										? <th key={`header_${key}`}><T>{key}</T> (s)</th>
										: <th key={`header_${key}`}><T>{key}</T></th>
								))}
							</tr>
						</thead>
						<tbody className={this.props.list.alert.refreshing ? 'semi-transparent' : undefined}>
							{ Object.keys(alerts).map(id => (
								<React.Fragment key={`row_${id}`}>
									<tr>
										<td>
											<i
												className="fa fa-trash-o"
												role="button"
												onClick={index => this.actions.toggleModal(true,
													<Alert
														toggle={this.actions.toggleModal}
														title="drop confirm"
														message="do you wish to continue"
														onConfirm={() => {
															this.actions.deleteData(`alert/id/${id}`);
															this.requestData();
														}}
													/>
												)}
												title={messages.drop || 'drop'} />
											<i
												className="fa fa-edit"
												role="button"
												style={selected===id ? {background: 'linear-gradient(to left top, lightgray, #f0f3f5)'} : {}}
												onClick={() => {this.handleItemEdit(id)}} title={messages.edit || 'edit'}
											/>
											<i
												className="fa fa-map-o"
												data-tip
												data-for={`inline_map_${id}`}
											/>
											<Tooltip id={`inline_map_${id}`} delayShow={200} delayUpdate={5000} className="map-tooltip" place="right">
												<LeafletMap style={{width: '300px', height: '300px'}} zoom="12" center={[alerts[id].lat, alerts[id].lon]}>
												<TileLayer
													url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
													attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
												/>
													<Marker position={[alerts[id].lat, alerts[id].lon]}/>
												</LeafletMap>
											</Tooltip>
										</td>
										{ Object.keys(header).map(key => (
											<td key={`row_${id}_cell_${key}`}>
												{ header[key] === 'enum'
													? <Badge color={alerts[id][key]==='OK' ? 'success' : alerts[id][key]==='ACTIVE' ? 'danger' : 'secondary'} className="p-2">
														{ alerts[id][key] &&
															<T lettercase="upper">{alerts[id][key].toLowerCase()}</T>
														}
													</Badge>
													: ( header[key] === 'bool'
														? ( alerts[id][key] === true
															? <i className="fa fa-check-square-o text-danger"/>
															: <i className="fa fa-square-o text-success"/>
														)
														: <span>{alerts[id][key]}</span>
													)
												}
											</td>
										))}
									</tr>
									{ selected === id &&
										<tr>
											<td colSpan="4">{form}</td>
										</tr>
									}
								</React.Fragment>
							))}
						</tbody>
					</Table>
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = (state) => ({
	list: state.list,
	i18n: state.i18n,
});

Alerts = connect(mapStateToProps)(Alerts);

export default Alerts;
