import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col } from 'reactstrap';

class JsonInput extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: this.props.value,
		};
	}

	handleChangeKey = (e, old_key) => {
		const new_key = e.target.value;
		const values = Object.keys(this.state.values).reduce((obj, key) => {
			if (key === old_key) {
				return {
					...obj,
					[new_key]: this.state.values[key],
				};
			}
			return {
				...obj,
				[key]: this.state.values[key],
			};
		}, {});
		this.setState({values}, () => {
			this.handleChange();
		});
	}

	handleChangeValue = (e, key) => {
		this.setState({
			values: {...this.state.values, [key]: e.target.value},
		}, () => {
			this.handleChange();
		});
	}

	handleChange = () => {
		this.props.onChange({
			target: {
				name: this.props.name,
				value: this.state.values,
			}
		});
	}

	removeValue = (old_key) => {
		this.setState({
			values: Object.keys(this.state.values).filter(key => key !== old_key).reduce((obj, key) => ({
				...obj,
				[key]: this.state.values[key],
			}), {})
		}, () => {
			this.handleChange();
		});
	}

	addValue = () => {
		this.setState({
			values: {...this.state.values, '': ''}
		});
	}

	render() {
		const { values } = this.state;
		return (
			<>
				{ Object.keys(values).map((key, index) => (
					<Row key={`jsonrow_${index}`}>
						<Col xs="6">
							<Input value={key} onChange={(e) => this.handleChangeKey(e, key)}/>
						</Col>
						<Col xs="5">
							<Input value={values[key]} onChange={(e) => this.handleChangeValue(e, key)}/>
						</Col>
						<Col xs="1" className="pl-0 ml-0 text-right">
							<i className="fa fa-minus-circle text-danger" role="button" onClick={() => this.removeValue(key)}/>
						</Col>
					</Row>
				))}
				<Row>
					<Col className="text-right">
						<i className="fa fa-plus-circle text-info" style={{fontSize: '120%'}} role="button" onClick={this.addValue}/>
					</Col>
				</Row>
			</>
		);
	}
}

JsonInput.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default JsonInput;
