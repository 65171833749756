import { StaticRoutes } from './model/routes';
import * as roles from 'core/model/roles';

const menu = [
	{
		name: 'settings',
		icon: 'fa fa-sliders',
		url: StaticRoutes.Settings,
		children: [
			{
				name: 'things',
				icon: 'fa fa-rss',
				url: StaticRoutes.Things,
				role: roles.EDITOR,
			},
			{
				name: 'sensors',
				icon: 'fa fa-microchip',
				url: StaticRoutes.Sensors,
				role: roles.EDITOR,
			},
			{
				name: 'properties',
				icon: 'fa fa-thermometer-three-quarters',
				url: StaticRoutes.Properties,
				role: roles.EDITOR,
			},
		]
	},
	{
		name: 'raw data',
		icon: 'fa fa-database',
		url: StaticRoutes.RawData,
		role: roles.REVIEWER,
	},
];

export default menu;
