import React, { Component } from 'react';
import T from 'modules/i18n';
import { Loading } from 'core/components';
import { getData } from 'core/ducks/update';
import { connect } from 'react-redux';
import { chunk } from 'lodash';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardTitle, CardHeader, Tooltip } from 'reactstrap';
import Chart from "react-apexcharts";

const chunks = 4; // number of chunks to split the inputs array. is equal to amount of Bootstrap columns (e.g. 4 * col-md-3)

const inputValidationMsgs = {
    VALID: '',
    EMPTY: 'Fill in a valid value.',
    NON_INTEGER: 'Value must be an integer',
    NON_NUMERIC: 'Value must be a number',
}

const status = {
    INITIAL: 'INITIAL',
    IS_CHECKING_FORM_VALIDATION: 'IS_CHECKING_FORM_VALIDATION',
    IS_FORM_DATA_SENT: 'FORM_DATA_SUBMITTED',
    IS_FETCHED_RESULTS: 'IS_FETCHED_RESULTS',
}

const fields = [
    {id: 'em_water', label: [ 'Em', <sub key="em_water">water</sub>], type: 'float'},
    {id: 'em_air', label: [ 'Em', <sub key="em_air">air</sub>], type: 'float'},
    {id: 'em_soil', label: [ 'Em', <sub key="em_soil">soil</sub>], type: 'float'},
    {id: 'em_sediment', label: [ 'Em', <sub key="em_sediment">sediment</sub>], type: 'float'},
    {id: 'time', label: 'Time', type: 'int'},
    {id: 't_units', label: 'Time Unit', type: 'select', description: 'Time Units', values: ['Hours', 'Days', 'Weeks', 'Months', 'Years'], value: 0},
    {id: 'den', label: ['Density (kg/m', <sup key="den">3</sup>, ')'], type: 'float'},
    {id: 'diameter', label: 'Diameter (nm)', type: 'float'},
    {id: 'psi_enp', label: [ 'psi', <sub key="psi_enp">enp</sub>, ' (mV)'], type: 'float'},
    {id: 'A_hamaker', label: [ 'A', <sub key="A_hamaker">Hamaker</sub>], type: 'float'},
    {id: 'valence', label: 'Valence', type: 'float'},
    {id: 'area', label: ['Area (m', <sup key="den">2</sup>, ')'], type: 'int'},
]

class Pec extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: status.INITIAL,
            inputs: [],
            results: null,
        }

        const { dispatch } = props;

        this.state.inputs = chunk(fields.map((x) => ({...x, inputErrorMsg: inputValidationMsgs.VALID})), chunks);
    }

    tooltipToggle(targetName) {
        if (!this.state[targetName]) {
            this.setState({
                [targetName]: {
                    tooltipOpen: true
                }
            });
        } else {
            this.setState({
                [targetName]: {
                    tooltipOpen: !this.state[targetName].tooltipOpen
                }
            });
        }
    };

    isTooltipOpen(targetName) {
        return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
    };

    handleInputChange(fieldId, fieldType, inputValue) {
        const value = parseFloat(inputValue);

        let inputErrorMsg;

        if (!value && value !==0) {
            inputErrorMsg = inputValidationMsgs.EMPTY;
        } else if (value.toString().length !== inputValue.length) {
            inputErrorMsg = inputValidationMsgs.NON_NUMERIC
        } else {
            switch (fieldType) {
                case 'int': {
                    inputErrorMsg = !Number.isInteger(value) ? inputValidationMsgs.NON_INTEGER : inputValidationMsgs.VALID;
                    break;
                }
                case 'float': {
                    inputErrorMsg = Number.isNaN(value) ? inputValidationMsgs.NON_NUMERIC : inputValidationMsgs.VALID;
                    break;
                }
                default:
                    inputErrorMsg = inputValidationMsgs.VALID;
            }
        }

        this.setState({
            inputs: chunk(this.state.inputs.flat().map(x => x.id === fieldId ? {
                ...x, value: value, 
                inputErrorMsg: inputErrorMsg, 
            } : x), chunks)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.status === status.IS_CHECKING_FORM_VALIDATION) {
            if (this.state.inputs.flat().some(x => x.inputErrorMsg)) {
                this.setState({ status: status.INITIAL })
            } else {
                this.submitForm();
            }
        }

        if (this.props.pending && !prevProps.pending) {
            this.setState({ status: status.IS_FORM_DATA_SENT })
        }

        if (!this.props.pending && prevProps.pending) {
            this.setState({
                results: this.props.response,
                status: status.IS_FETCHED_RESULTS
            })
        }
    }

    submitForm() {
        let query = '';
        this.state.inputs.flat().forEach((x) => {
            query += x.value || x.value === 0 ? `&${x.id}=${x.value}` : '';
        })
        query = `pec?${query.substring(1)}`;

        this.props.dispatch(getData(query));
    }

    handleGetData(e) {
        e.preventDefault();

        this.setState({ 
            inputs: chunk(this.state.inputs.flat().map(x => !x.value && x.value !==0 ? {
                ...x, inputErrorMsg: inputValidationMsgs.EMPTY
            } : x), chunks),
            status: status.IS_CHECKING_FORM_VALIDATION
        })
    }

    render() {
        return(
            <Card>
                <CardHeader>
                    <span>Predicted Environmental Concentrations</span>
                    <span id={'tooltip_pec_info'} className="text-dark ml-md-3" style={{ cursor: 'help' }}><i className="fa fa-info-circle"></i></span>
                    <Tooltip
                        placement="bottom-start"
                        innerClassName="bg-light text-dark mw-100 p-3"
                        isOpen={this.isTooltipOpen('tooltip_pec_info')} 
                        target={`tooltip_pec_info`}
                        toggle={() => this.tooltipToggle('tooltip_pec_info')}
                        >
                            <span><i>Meesters, J. A., Koelmans, A. A., Quik, J. T., Hendriks, A. J., & van de Meent, D.</i> (2014). <strong>Multimedia modeling of engineered nanoparticles with SimpleBox4nano: model definition and evaluation.</strong> Environmental science & technology, 48(10), 5726-5736</span>
                    </Tooltip> 
                </CardHeader>
                <CardBody>
                    <Form>
                        { this.state.inputs.map((x, i) => (
                            <div className="row" key={`row_${i}`}>
                                { x.map(y => (
                                    <div className="col-md-3" key={`field_${y.id}`}>
                                        <FormGroup>
                                            <Label> 
                                                <span>{y.label}</span>
                                                <span id={`tooltip_${y.id}`} className="text-primary ml-md-3" style={{ cursor: 'help' }}><i className="fa fa-info-circle"></i></span>
                                                <Tooltip 
                                                    placement="right" 
                                                    isOpen={this.isTooltipOpen(`tooltip_${y.id}`)} 
                                                    target={`tooltip_${y.id}`}
                                                    toggle={() => this.tooltipToggle(`tooltip_${y.id}`)}
                                                    >
                                                        {/* {y.description} */}
                                                        <T>{ `pec_${y.id}` }</T>
                                                </Tooltip> 
                                            </Label>
                                            {y.type === 'select' ? (
                                                <Input type='select' onChange={(e) => (y.value = e.target.selectedIndex)}>
                                                    {y.values.map((v, i) => (
                                                        <option key={`option_${i}`} value={i}>{v}</option>
                                                    ))}
                                                </Input>
                                            ) : (
                                                // <Input onChange={(e) => (y.value = parseFloat(e.target.value))} type="number" step={y.type === 'int' ? 1 : undefined} />
                                                <Input onChange={(e) => this.handleInputChange(y.id, y.type, e.target.value)} type="text" step={y.type === 'int' ? 1 : undefined} />
                                            )}
                                        </FormGroup>
                                        { (y.inputErrorMsg) && (
                                            <p className='text-danger'>{ y.inputErrorMsg }</p>
                                        ) }
                                    </div>
                                )) }
                            </div>
                        )) }
                        <Button type="submit" onClick={(e) => this.handleGetData(e)} className="btn btn-success mt-3 mb-3">
                            <T>calculate</T>
                        </Button>
                    </Form>

                    {this.state.status === status.IS_FORM_DATA_SENT &&
                        <Card>
                            <Loading />
                        </Card>
                    }

                    {this.state.status === status.IS_FETCHED_RESULTS &&
                        <div>
                            <div className="row">
                                { this.state.results.plots.map((plot, i) => (
                                    <div className="col-md-6" key={`chart_${i}`}>
                                        <Card>
                                            <CardBody>
                                                <CardTitle><strong>{ plot.label }</strong></CardTitle>
                                                <Chart 
                                                    type="line" 
                                                    options={{
                                                        chart: {
                                                            zoom: {
                                                                enabled: true
                                                            },
                                                            animations: {
                                                                easing: "linear",
                                                                dynamicAnimation: {
                                                                    speed: 500
                                                                }
                                                            }
                                                        },
                                                        stroke: {
                                                            curve: 'smooth',
                                                        },
                                                        xaxis: {
                                                            type: 'category',
                                                            labels: {
                                                                formatter: function(v) {
                                                                    return v % 1 == 0 ? parseInt(v) : parseFloat(v).toFixed(2)
                                                                }
                                                            },
                                                            tickAmount: plot.series[0].data[plot.series[0].data.length - 1][0] > 20 ? 20 : plot.series[0].data[plot.series[0].data.length - 1][0],
                                                            title: {
                                                                text: plot.xlabel,
                                                            }
                                                        },
                                                        yaxis: {
                                                            forceNiceScale: true,
                                                            decimalsInFloat: 0,
                                                            title: {
                                                                text: plot.ylabel,
                                                                offsetY: 10,
                                                                style: {
                                                                    fontWeight: '600'
                                                                }
                                                            }
                                                        },
                                                    }} 
                                                    series={ plot.series } 
                                                />
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Card>
                                        <CardBody>
                                            {/* <CardTitle><strong>Environmental Concentrations (kg/m<sup>3</sup>)</strong></CardTitle> */}
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover text-center align-middle">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '10%' }} rowSpan='2' colSpan='1'></th>
                                                            <th style={{ width: '30%' }} rowSpan='2' colSpan='1' className="align-middle"><T>pec_env_conc</T> (kg/m<sup>3</sup>)</th>
                                                            <th style={{ width: '60%' }} rowSpan='1' colSpan='2'><T>pec_mass</T> (kg)</th>
                                                        </tr>
                                                        <tr>
                                                            <th><T>pec_start</T></th>
                                                            <th><T>pec_finish</T></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th>Water</th>
                                                            <td>{ this.state.results.pec.water }</td>
                                                            <td>{ this.state.results.mass.Water.start }</td>
                                                            <td>{ this.state.results.mass.Water.finish }</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Air</th>
                                                            <td>{ this.state.results.pec.air }</td>
                                                            <td>{ this.state.results.mass.Air.start }</td>
                                                            <td>{ this.state.results.mass.Air.finish }</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Soil</th>
                                                            <td>{ this.state.results.pec.soil }</td>
                                                            <td>{ this.state.results.mass.Soil.start }</td>
                                                            <td>{ this.state.results.mass.Soil.finish }</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Sediment</th>
                                                            <td>{ this.state.results.pec.sediment }</td>
                                                            <td>{ this.state.results.mass.Sediment.start }</td>
                                                            <td>{ this.state.results.mass.Sediment.finish }</td>
                                                        </tr>
                                                        <tr>
                                                            <th>m-loss (kg)</th>
                                                            <td colSpan="3">{ this.state.results.mloss }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    }
                </CardBody>
            </Card>
        )
    }

}

const mapStateToProps = (state) => ({
    pending: state.update.pending,
    response: state.update.response,
});

Pec = connect(mapStateToProps)(Pec);

export default Pec;