import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ModalHeader, Modal, ModalBody, Row, Col, Card, CardBody, Button } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';

import { getData, updateData } from 'core/ducks/update';
import T from 'modules/i18n';

import '../../style/tags.scss';

class AssignGroup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tags: props.data.group ? JSON.parse(props.data.group).map(value => ({id: value, name: value})) : [],
			suggestions: [],
			pending: true,
		};
	}

	componentDidMount() {
		this.props.dispatch(
			getData('userGroup/scope/groups')
		).then(response => {
			this.setState({
				suggestions: response ? response.map(value => ({id: value, name: value})) : [],
				pending: false,
			});
		}).catch(err => {
			console.warn(err);
		});
	}

	handleDelete = (i) => {
		const tags = this.state.tags.slice(0);
		tags.splice(i, 1);
		this.setState({ tags });
	}

	handleAddition = (tag) => {
		const { tags } = this.state;
		const index = tags.findIndex(elem => elem.id === tag.id);
		let newTags = index !== -1 ? [...tags.slice(0, index), ...tags.slice(index + 1)] : [...tags];
		newTags = [].concat(newTags, {id: tag.name, name: tag.name});
		this.setState({ tags: newTags });
	}

	handleSave = () => {
		const groups = this.state.tags.map(elem => elem.name);
		this.props.dispatch(
			updateData('userGroup/scope/assign', {user: this.props.data.index, groups})
		).then(() => this.props.toggle())
		.catch(err => console.warn(err));
	}

	render() {
		const { modalOpen, toggle, data } = this.props;

		if (this.state.pending)
			return null;

		return (
			<Modal isOpen={modalOpen} toggle={toggle} className="modal-md">
				<ModalHeader toggle={toggle}>{<T>assign to group</T>}</ModalHeader>
				<ModalBody>
					<Card>
						<CardBody>
							<Row>
								<Col className="text-center font-italic" style={{fontSize: '130%'}}>
									{(data.firstname || '') + ' ' + (data.lastname || '')}
								</Col>
							</Row>
							<Row>
								<Col className="text-center font-weight-bold">{data.username}</Col>
							</Row>
							<Row>
								<Col>
									<ReactTags
										tags={this.state.tags}
										suggestions={this.state.suggestions}
										placeholder="Select group"
										autofocus={false}
										allowNew={true}
										maxSuggestionsLength={8}
										minQueryLength={1}
										onDelete={this.handleDelete}
										onAddition={this.handleAddition}
										autoresize={false}
									/>
								</Col>
							</Row>
							<Row>
								<Col className="text-right">
									<Button className="mr-2" color="success" onClick={this.handleSave}>
										<T>save</T>
									</Button>
									<Button className="mr-2" color="warning" onClick={() => toggle()}>
										<T>cancel</T>
									</Button>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	modalOpen: state.ui.modal.modalOpen
});

AssignGroup = connect(mapStateToProps)(AssignGroup);

export default AssignGroup;