import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Button } from 'reactstrap';

import { updateData, postData } from 'core/ducks/update';
import { Tr, Td } from 'css-table';
import ParameterModal from './parameterModal';

class EditItem extends Component {

	constructor(props) {
		super(props);
		this.initialValues = {...props.stream};
		this.state = {
			values: {...this.initialValues},
			isModalOpen: false,
			modalScope: null,
			activeStream: null,
			hasBeenEdited: false,
		}
	}

	handleChange = (event, index) => {
		const { name, value } = event.target;
		const newValues = {
			...this.state.values,
			[name]: value,
		};
		const hasBeenEdited = (JSON.stringify(newValues) !== JSON.stringify(this.initialValues));
		this.setState({
			values: {...newValues},
			hasBeenEdited,
		});
	}

	handleCellClick = (scope, stream) => {
		this.setState({isModalOpen: true, modalScope: scope, activeStream: stream});
	}

	handleAccept = (e, values) => {
		e.preventDefault();
		const { modalScope } = this.state;
		const newValues = {
			...this.state.values,
			[modalScope]: {...values}
		};
		const hasBeenEdited = (JSON.stringify(newValues) !== JSON.stringify(this.initialValues));
		this.setState({
			values: newValues,
			hasBeenEdited,
		});
		this.closeModal();
	}

	closeModal = () => {
		this.setState({isModalOpen: false, modalScope: null, activeStream: null});
	}

	handleSubmit = () => {
		const { token, thing, description, unit, ...values } = this.state.values;
		const data = {description, unit, sensor: values.sensor.token, property: values.property.token};
		if (token) {
			this.props.dispatch(updateData(`datastream/token/${token}`, data))
				.then(() => this.setState({initialValues: {...this.state.values}, hasBeenEdited: false}));
		} else {
			this.props.dispatch(postData('datastream', {...data, thing: this.props.thing}))
				.then((response) => this.setState({
					initialValues: {...this.state.values},
					values: {...this.state.values, token: response['api-key']},
					hasBeenEdited: false
				}, () => {
					this.props.onNewSubmit(this.state.values);
				}));
		}
	}

	render() {
		const { values, isModalOpen, modalScope, activeStream, hasBeenEdited } = this.state;
		const { messages, stream } = this.props;

		return (
			<Tr>
				<Td>
					<FormGroup>
						<Input name="description" value={values.description} onChange={this.handleChange}/>
					</FormGroup>
				</Td>
				{['unit', 'sensor', 'property'].map(scope => (
					<Td key={`cell_${scope}`} onClick={() => this.handleCellClick(scope, values)} title={messages['click to edit'] || 'Click to edit'}>
						{values[scope].symbol || values[scope].label || values[scope].name}
					</Td>
				))}
				<Td>
					<Button
						outline
						color="light"
						title={hasBeenEdited ? messages.save || 'Save' : undefined}
						className="mr-2"
						disabled={!hasBeenEdited}
						onClick={this.handleSubmit}
					>
						<i className={`d-inline-block fa fa-floppy-o ${hasBeenEdited ? 'text-success' : 'text-muted'}`}/>
					</Button>
					<Button outline color="light" title={messages.delete || 'Delete'} onClick={() => this.props.onDelete(stream.token)}>
						<i className="d-inline-block fa fa-trash-o text-warning"/>
					</Button>
				</Td>
				{ isModalOpen &&
					<ParameterModal
						isOpen={isModalOpen}
						toggle={this.closeModal}
						scope={modalScope}
						stream={activeStream}
						handleAccept={this.handleAccept}
					/>
				}
			</Tr>
		);
	}
}

EditItem = connect(null)(EditItem);

export default EditItem;
