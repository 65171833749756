import React, { Component } from 'react';
import { Col } from 'reactstrap';

class Dashboard extends Component {

	render() {
		return (
			<React.Fragment>
				{ React.Children.map(this.props.children, (Child) =>
					<React.Fragment>
						<Col className="d-none d-xl-block" xl="1"/>
						<Col xs="12" md="6" xl="4">
							{Child}
						</Col>
						<Col  className="d-none d-xl-block" xl="1"/>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}
}

export default Dashboard;
