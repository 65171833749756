import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defaults as defaultControls } from "ol/control";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import TileWMS from 'ol/source/TileWMS';
import OSM from "ol/source/OSM";
import View from "ol/View";
import 'ol-ext/dist/ol-ext.css';
import LayerPopup from 'ol-ext/control/LayerPopup';
import moment from 'moment';
import { requestData } from 'core/ducks/list';
import LegendInfoControl from '../../components/legend/legend';
import TimeSlider from '../../components/timeslider/timeslider';
import { Loading, Error } from 'core/components';

class Cams extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            min: moment().startOf('hour').valueOf(),
            max: moment().add(2, 'days').startOf('hour').valueOf(),
            step: 3600000,
        };
    }

    componentDidMount() {
        this.props.dispatch(requestData('forecasts'));
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.pending && !this.props.pending) || (prevProps.refreshing && !this.props.refreshing)) {
            const data = this.props.data;
            let LegendControl = new LegendInfoControl();
            let osmLayer = new TileLayer({
                source: new OSM()
            });
            let layers = data.layers.map(layer => new TileLayer({
                id: layer.id,
                title: layer.title,
                baseLayer: layer.baseLayer,
                visible: layer.visible,
                source: new TileWMS({
                    url: layer.url,
                    params: {
                        LAYERS: layer.layer,
                        token: layer.token,
                    },
                }),
                legend: layer.legend,
                opacity: layer.opacity,
            }));

            let map_view = new View({
                projection: 'EPSG:4326',
                center: data.map.center,
                zoom: data.map.zoom,
                minZoom: data.map.minZoom,
                extent: data.map.extent
            });
            let map = new Map({
                layers: [osmLayer, ...layers],
                controls: defaultControls({attribution: false}).extend([
                    new LayerPopup(),
                    LegendControl
                ]),
                target: 'cams_map',
                view: map_view
            });
            LegendControl.set(map);

            this.setState({
                map: map,
            });
        }
    }

    render() {

        const { pending, status } = this.props;
        if (pending) {
			return <Loading />;
		} else if (status !== 200 && status !== '') {
			return <Error status={status} errorMsg={""} />;
		}

        return (
            <div className="sensors full-content">
                <div id="cams_map" className="map h-100"/>
                <TimeSlider
                    min={this.state.min}
                    max={this.state.max}
                    step={this.state.step}
                    map={this.state.map}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pending: state.list.forecasts.pending,
    refreshing: state.list.forecasts.refreshing,
    status: state.list.forecasts.status,
    data: state.list.forecasts.data,
    center: state.ui.settings.values.map.center,
    zoom: state.ui.settings.values.map.zoom
});

Cams = connect(mapStateToProps)(Cams);

export default Cams;
