import React, { Component } from 'react';

class Td extends Component {

	render() {

		const { className, ...otherProps } = this.props;
		const classString = className ? `td ${className}` : 'td';

		return (
			<div className={classString} {...otherProps}>
				{this.props.children}
			</div>
		);
	}
}

export default Td;
