const Sensors = '/sensors';
const SensorsMap = Sensors + '/map';
let DataAnalysis = Sensors + '/data-analysis';
const Cams = '/forecasts';

export const StaticRoutes = {
	Sensors,
	SensorsMap,
	DataAnalysis,
	Cams,
};

DataAnalysis = DataAnalysis + '/(stream)?/:stream([A-fa-f0-9]{32})?';

export const DynamicRoutes = {
	DataAnalysis,
};

export const routes = [
	{
		url: Sensors,
		to: SensorsMap,
		exact: true,
	},
	{
		url: SensorsMap,
		name: 'map',
		component: 'SensorsMap',
	},
	{
		url: DataAnalysis,
		name: 'Data Analysis',
		component: 'SensorDataAnalysis',
		exact: true
	},
	{
		url: Cams,
		name: 'forecasts',
		component: 'Cams'
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
