import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Label, Row, Col, Button, Collapse } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from 'prop-types';

import { getData } from 'core/ducks/update';
import T from 'modules/i18n';

const customStyles = {
	menu: (provided, state) => ({
		...provided,
		zIndex: 2000,
	})
};

class Filters extends Component {

	constructor(props) {
		super(props);
		this.state = {
			encTypes: [],
			ready: false,
			values: {},
			label: props.label,
			point: null,
			isFiltersOpen: props.startOpen,
		};
		this.mapRef = React.createRef();
	}

	componentDidMount() {
		this.props.dispatch(
			getData('search/distinct/enc_type')
		).then(r => {
			this.setState({
				encTypes: r,
				ready: true,
				values: r.reduce((obj, key) => ({
					...obj,
					[key]: ''
				}), {})
			});
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.label !== this.state.label) {
			if (this.state.label !== '') {
				this.props.dispatch(
					getData(`thing/token/${this.state.label.value}`)
				).then(r => this.setState({point: [r.location.lat, r.location.lon]}));
			} else {
				this.setState({point: null});
			}
		}
	}

	async loadPlace(encType, search, loadedOptions, { page }) {
		const url = search === '' ? `search/enc_type/${encType}/page/${page}` : `search/enc_type/${encType}/search/${search}/page/${page}`;
		const response = await this.props.dispatch(
			getData(url)
		);
		return {
			options: response.results.map(result => ({value: result, label: result})),
			hasMore: response.hasMore,
			additional: {
				page: page + 1,
			},
		};
	}

	handleChange = (e, type) => {
		if (this.state.values[type] !== e)
			this.setState({
				values: {
					...this.state.values,
					[type]: e || '',
				},
				label: '',
			});
	}

	constructFilters = () => {
		const { values } = this.state;
		const filters = Object.keys(values)
			.filter(key => values[key] !== '')
			.map(key => `${key}:${values[key].value}`);
		return filters.join(';');
	}

	async loadLabels(search, loadedOptions, { page }) {
		const filters = this.constructFilters();
		let url = ['search/field/label'];
		if (filters !== '')
			url.push(`filter/${filters}`);
		if (search !== '')
			url.push(`search/${search}`);
		url.push(`page/${page}`);
		url = url.join('/');
		const response = await this.props.dispatch(
			getData(url)
		);
		return {
			options: response.results,
			hasMore: response.hasMore,
			additional: {
				page: page + 1,
				filters: this.state.values,
			},
		};
	}

	handleLabelChange = (label) => {
		this.setState({
			label: label || '',
		});
	}

	render() {

		const { ready, encTypes, values, point, label, isFiltersOpen } = this.state;
		const { mapSettings } = this.props;

		if (!ready)
			return null;

		return (
			<>
				<FormGroup tag="fieldset" className="bg-light px-3">
					<legend style={{fontSize: '100%'}}>
						<T>filters</T>
						<span style={{position: 'relative', float: 'right'}}>
							<i className={`fa fa-${isFiltersOpen ? 'minus' : 'plus'}-square`} role="link" onClick={() => this.setState({isFiltersOpen: !isFiltersOpen})}/>
						</span>
					</legend>
					<Collapse isOpen={isFiltersOpen}>
						{ encTypes.map(type =>
							<FormGroup key={`select_${type}`}>
								<Label className="text-primary"><T>{type}</T></Label>
								<AsyncPaginate
									styles={customStyles}
									name={type}
									value={values[type]}
									loadOptions={(search, loadedOptions, page) => this.loadPlace(type, search, loadedOptions, page)}
									onChange={(e) => this.handleChange(e, type)}
									isClearable={true}
									additional={{
										page: 1
									}}
								/>
							</FormGroup>
						)}
					</Collapse>
				</FormGroup>
				<FormGroup tag="fieldset">
					<FormGroup>
						<Label className="text-primary"><T>station</T></Label>
						<AsyncPaginate
							styles={customStyles}
							name="label_select"
							key={JSON.stringify(values)}
							value={label}
							loadOptions={(search, loadedOptions, page) => this.loadLabels(search, loadedOptions, page)}
							onChange={(e) => this.handleLabelChange(e)}
							isClearable={true}
							additional={{
								page: 1
							}}
						/>
					</FormGroup>
				</FormGroup>
				<Row>
					<VisibilitySensor onChange={() => {this.mapRef.current.leafletElement.invalidateSize()}}>
						<LeafletMap
							ref={this.mapRef}
							style={{width: 100 + '%', height: 250 + 'px'}}
							center={point ? point : mapSettings.center}
							zoom={point && mapSettings.zoom < 7 ? 7 : mapSettings.zoom}
						>
							<TileLayer
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
							/>
							{ point &&
								<Marker position={point} style={{width: 10 + 'px', height: 10 + 'px'}}/>
							}
						</LeafletMap>
					</VisibilitySensor>
				</Row>
				<Row>
					<Col className="text-right">
						<Button
							outline={label===''}
							disabled={label===''}
							color="success"
							onClick={() => this.props.handleSelect(label)}
						>
							<T>select</T>
						</Button>
					</Col>
				</Row>
			</>
		);
	}

}

Filters.propTypes = {
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]).isRequired,
	handleSelect: PropTypes.func.isRequired,
	startOpen: PropTypes.bool,
};

Filters.defaultProps = {
	startOpen: true,
};

const mapStateToProps = (state) => ({
	mapSettings: state.ui.settings.values.map,
});

Filters = connect(mapStateToProps)(Filters);

export default Filters;
