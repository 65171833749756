import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import components from 'modules/components';
import store from './store';
import './index.css';
import App from 'core/App';
import * as serviceWorker from './serviceWorker';

const { IntlProvider } = components;

if (typeof IntlProvider === 'function') {
	ReactDOM.render(
		<Provider store={store}>
			<IntlProvider>
				<App />
			</IntlProvider>
		</Provider>,
		document.getElementById('root')
	);
} else {
	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>,
		document.getElementById('root')
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
