import React, { Component } from 'react';

class Thead extends Component {

	render() {

		let children = Array.isArray(this.props.children) ? this.props.children : [this.props.children];
		children = children.map((child, index) => {
			return React.cloneElement(child, {scope: 'thead', key: `child_${index}`});
		});

		return (
			<thead>
				{children}
			</thead>
		);
	}
}

Thead.displayName = 'Thead';

export default Thead;
