import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Input, Label,
	CustomInput, InputGroup, InputGroupAddon, InputGroupText, Button, Col,
} from 'reactstrap';

import { updateData, postData } from 'core/ducks/update';
import { DatastreamsModal } from '../modals';
import T from 'modules/i18n';

class Things extends Component {

	constructor(props) {
		super(props);
		this.emptyLocation = {enc_type: '', place: ''};
		this.initialValues = {
			label: '',
			description: '',
			is_active: false,
			location: [{...this.emptyLocation}],
		};
		this.state = {
			activeTab: 0,
			isDatastreamModalOpen: false,
			values: {...this.initialValues},
			coordinates: ['', ''],
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.context.activeLayer !== this.props.context.activeLayer && this.props.context.activeLayer) {
			const { properties } = this.props.context.activeLayer;
			const { geometry } = this.props.context.activeLayer;
			this.initialValues = {
				label: properties.label,
				description: properties.description,
				location: properties.location.map(location => ({enc_type: Object.keys(location)[0] || '', place: Object.values(location)[0] || ''})),
				is_active: properties.active,
			};
			this.setState({
				values: {...this.state.values, ...this.initialValues},
				coordinates: [...geometry.coordinates],
			});
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { label, description, location } = this.state.values;
		const { coordinates } = this.state;
		const token = this.props.context.activeLayer.id;
		const data = {label, description, place: location, location: {lat: coordinates[1], lon: coordinates[0]}};
		if (!token) {
			this.props.dispatch(postData('thing', data)).then((response) => {
				const { context } = this.props;
				context.update();
				context.set({activeLayer: {...context.activeLayer, id: response['api-key']}});
				this.setState({isDatastreamModalOpen: true});
			});
		} else {
			this.props.dispatch(updateData(`thing/token/${token}`, data)).then(() => this.props.context.update());
		}
	}

	toggleTab = (activeTab) => {
		if (activeTab !== this.state.activeTab)
			this.setState({activeTab});
	}

	handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[name]: type === 'checkbox' ? checked : value,
			}
		});
		if (name === 'is_active') {
			const { context } = this.props;
			this.props.dispatch(
				updateData(`thing/token/${context.activeLayer.id}`, {is_active: checked}, false)
			).then(() => context.update());
		}
	}

	handleLocationChange = (event, index) => {
		const { name, value } = event.target;
		const { location } = this.state.values;
		this.setState({
			values: {
				...this.state.values,
				location: [...location.slice(0, index), {...location[index], [name]: value}, ...location.slice(index + 1)],
			}
		});
	}

	handleLocationAdd = () => {
		this.setState({
			values: {
				...this.state.values,
				location: [...this.state.values.location, {...this.emptyLocation}]
			}
		});
	}

	handleLocationRemove = (index) => {
		this.setState({
			values: {
				...this.state.values,
				location: [...this.state.values.location.slice(0, index), ...this.state.values.location.slice(index + 1)],
			}
		});
	}

	handleCoordinatesChange = (event) => {
		const { name, value } = event.target;
		const index = name === 'lon' ? 0 : 1;
		const coordinates = [
			...this.state.coordinates.slice(0, index),
			value,
			...this.state.coordinates.slice(index + 1)
		];
		this.props.context.moveMarker(coordinates);
	}

	render() {

		const { activeTab, values, coordinates } = this.state;
		const { activeLayer } = this.props.context;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<>
				<Nav tabs>
					<NavItem>
						<NavLink
							className={activeTab===0 ? 'active text-info p-2' : 'border border-secondary p-2'}
							onClick={() => { this.toggleTab(0); }}
						>
							<T>{(activeLayer && !activeLayer.id) ? 'add' : 'edit'}</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={activeTab} className="scroller mx-2 mt-2">
					<TabPane tabId={0}>
						{ activeLayer ?
							<>
								<CustomInput
									type="switch"
									id="activate_switch"
									className="float-right"
									name="is_active"
									checked={values.is_active}
									onChange={this.handleChange}
									disabled={activeLayer.id ? false : true}
								/>
									<Button
										disabled={!activeLayer.id}
										outline
										color="warning"
										className="float-right mr-3"
										onClick={() => this.setState({isDatastreamModalOpen: true})}
									>
										Datastreams
									</Button>
								<div className="clearfix"/>
								<Form onSubmit={this.handleSubmit} className="mt-2">
									<FormGroup>
										<Label>
											<T>label</T>
											<Input name="label" value={values.label} onChange={this.handleChange}/>
										</Label>
									</FormGroup>
									<FormGroup>
										<Label>
											<T>description</T>
											<Input name="description" value={values.description} onChange={this.handleChange}/>
										</Label>
									</FormGroup>
									<fieldset className="border-top">
										<legend style={{fontSize: '120%', width: 'auto'}} className="ml-3"><T>coordinates</T></legend>
										{['lon', 'lat'].map((coord, index) => (
											<FormGroup key={`coord_${coord}`} row>
												<Label sm="4" className="py-2"><T>{coord}</T></Label>
												<Col sm="8" className="py-0">
													<Input
														type="number"
														name={coord}
														value={coordinates[index]}
														onChange={this.handleCoordinatesChange}
														step={0.00000001}
													/>
												</Col>
											</FormGroup>
										))}
									</fieldset>
									<fieldset className="border-bottom border-top">
										<legend style={{fontSize: '120%', width: 'auto'}} className="ml-3"><T>location</T></legend>
										{ values.location.map((location, index) => (
											<FormGroup key={`location_${index}`} className="p-0">
												<InputGroup>
													<Input
														className="px-1"
														placeholder={messages.type || 'Type'}
														name="enc_type"
														value={location.enc_type}
														onChange={(e) => this.handleLocationChange(e, index)}
													/>
													<InputGroupAddon addonType="prepend">
														<InputGroupText className="px-1">:</InputGroupText>
													</InputGroupAddon>
													<Input
														className="px-1"
														placeholder={messages.place || 'Place'}
														name="place"
														value={location.place}
														onChange={(e) => this.handleLocationChange(e, index)}
													/>
													<i role="link" className="mt-2 ml-2 fa fa-minus-circle" onClick={() => this.handleLocationRemove(index)}/>
												</InputGroup>
											</FormGroup>
										))}
										<i
											role="button"
											style={{fontSize: '120%'}}
											className="fa fa-plus-circle float-right"
											onClick={this.handleLocationAdd}
										/>
										<div className="clearfix"/>
									</fieldset>
									<div className="mt-3 text-right">
										<Button color="success" className="mr-2"><T>{!activeLayer.id ? 'submit' : 'update'}</T></Button>
									</div>
								</Form>
							</>
							:
							<p><T>create a new station or select one on the map</T></p>
						}
					</TabPane>
				</TabContent>
				{ this.state.isDatastreamModalOpen &&
					<DatastreamsModal
						isOpen={this.state.isDatastreamModalOpen}
						toggle={() => {
							this.setState({isDatastreamModalOpen: false});
							this.props.context.update();
						}}
						thing={activeLayer.id}
					/>
				}
			</>
		);
	}
}

const mapStateToProps = (state) => ({

});

Things = connect(mapStateToProps)(Things);

export default Things;
