import config from  '../../package.json';
import coreMenu from 'core/menu';

const { modules } = config;

export let reducers = {};
export let headerItems = {};
export let menuReducers = [];
export let menuInitialState = {};
export let dashboardItems = [];
export let StaticRoutes = {};
export let DynamicRoutes = {};
export let Pages = {};
export let ErrorPages = {};
export let routes = [];
export let menuItems = [];
export let CustomMenuItems = [];

const coreMenuTitles = coreMenu.map(item => item.name);

if (modules)
	modules.forEach((module) => {
		try {
			const content = require(`../modules/${module}/config`).default;
			if (content.reducers)
				reducers = {...reducers, ...content.reducers};
			if (content.headerItems)
				headerItems = {...headerItems, ...content.headerItems};
			if (content.menuReducers) {
				menuReducers = [...menuReducers, content.menuReducers];
				menuInitialState = {...menuInitialState, ...content.menuInitialState};
			}
			if (content.dashboardItems)
				dashboardItems = [...dashboardItems, ...content.dashboardItems];
			if (content.StaticRoutes)
				StaticRoutes = {...StaticRoutes, ...content.StaticRoutes};
			if (content.DynamicRoutes)
				DynamicRoutes = {...DynamicRoutes, ...content.DynamicRoutes};
			if (content.Pages)
				Pages = {...Pages, ...content.Pages};
			if (content.ErrorPages)
				ErrorPages = {...ErrorPages, ...content.ErrorPages};
			if (content.routes)
				routes = [...routes, ...content.routes];
			if (content.menuItems) {
				const items = content.menuItems.filter(item => {
					let index = coreMenuTitles.indexOf(item.name);
					if (index !== -1)
						coreMenu[index] = {...coreMenu[index], children: [...coreMenu[index].children, ...item.children]};
					return (index === -1);
				});
				menuItems = [...menuItems, ...items];
			}
			if (content.CustomMenuItems)
				CustomMenuItems = [...CustomMenuItems, ...content.CustomMenuItems];
		} catch {

		}
	});

menuItems = [...coreMenu, ...menuItems];
