import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import 'ol/ol.css'
import 'ol-ext/dist/ol-ext.css'
import Map from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultControls } from 'ol/control';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import { Circle as CircleStyle, Stroke, Fill, Text, Style } from 'ol/style';
import SelectCluster from 'ol-ext/interaction/SelectCluster'
import OverlayPopup from '../../components/ol-ext/overlay/Popup';
import { requestData } from "core/ducks/list";
import { LayerControl, MapInfoWindow, TimeFilter } from "../modals"
import { buildPath } from "core/model/lib/urlTools";
import { DynamicRoutes } from "../../model/routes";
import { Loading, Error } from 'core/components';

import '../../style/sensors.scss';
class SensorsMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layers: null,
            stream: null,
            showInfo: false,
            timeFilterVisibility: false,
            from: '',
            to: new Date().toISOString().split("T")[0],
            activeLayer: null
        };

        this.map = new Map({
            layers: [
                new TileLayer({
                    source: new OSM()
                })
            ],
            controls: defaultControls({ attribution: true }).extend([]),
            view: new View({
                center: fromLonLat([props.center[1], props.center[0]]),
                zoom: props.zoom,
                minZoom: 3,
            })
        });

        this.map.on('pointermove', evt => {
            if (!evt.dragging) {
                this.map.getTargetElement().style.cursor = this.map.hasFeatureAtPixel(this.map.getEventPixel(evt.originalEvent)) ? 'pointer' : '';
            }
        });

        this.setPropertyLayers = this.setPropertyLayers.bind(this);
        this.periodSet = this.periodSet.bind(this);
        this.styleFunction = this.styleFunction.bind(this);
        this.activeLayer = this.activeLayer.bind(this);
    }

    componentDidMount() {
        let t = this;



        this.setPropertyLayers();

        // Popup overlay
        let popup = new OverlayPopup({
            popupClass: "default", //"tooltips", "warning" "black" "default", "tips", "shadow",
            closeBox: true,
            showDataAnalysis: true,
            textDataAnalysis: this.props.i18n['Data Analysis'],
            onshow: function () {

            },
            onclose: function () {

            },
            dataAnalysis: function () {
                t.props.history.push(buildPath(DynamicRoutes.DataAnalysis, [t.state.stream.feature.get('stream')]));
            },
            positioning: 'auto',
            autoPan: true,
            autoPanAnimation: { duration: 250 }
        });
        this.map.addOverlay(popup);


        // Style for selection
        const setFeatureStyle = (feature, resolution) => {
            let label = ' ';
            try {
                label = feature.get('features')[0].get('value')
            } catch (err) { }
            return new Style({
                image: new CircleStyle({
                    radius: 7,
                    stroke: new Stroke({
                        color: "rgba(153, 1, 0 ,0.7)",
                        width: 2
                    }),
                    fill: new Fill({
                        color: "rgb(176,43,72)"
                    })
                }),
                stroke: new Stroke({
                    color: "#fff",
                    width: 1
                }),
                text: new Text({
                    textAlign: 'center',
                    textBaseline: 'middle',
                    font: '10px Calibri,sans-serif',
                    fill: new Fill({ color: '#fff' }),
                    placement: 'point',
                    overflow: 'true',
                    text: label.toString(),
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.6)',
                        width: 3,
                    }),
                }),
            });
        }

        // Style for the clusters
        const getStyle = (feature, resolution) => {
            const size = feature.get('features').length;
            let maxFeatureCount = 0;
            const features = this.state.activeLayer.getSource().getFeatures();
            let f, radius;
            for (let i = features.length - 1; i >= 0; --i) {
                f = features[i];
                const originalFeatures = f.get('features');
                const extent = createEmpty();
                let j, jj;
                for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
                    extend(extent, originalFeatures[j].getGeometry().getExtent());
                }
                maxFeatureCount = Math.max(maxFeatureCount, jj);
                radius = (0.25 * (getWidth(extent) + getHeight(extent))) / resolution;
                f.set('radius', radius);
            }
            const style = new Style({
                image: new CircleStyle({
                    radius: 30,
                    fill: new Fill({
                        color: [255, 153, 0, Math.min(0.8, 0.4 + size / maxFeatureCount)],
                    }),
                }),
                text: new Text({
                    text: size.toString() + ' ' + this.props.i18n['things'],
                    fill: new Fill({
                        color: '#fff',
                    }),
                    stroke: new Stroke({
                        color: 'rgba(0, 0, 0, 0.6)',
                        width: 3,
                    }),
                }),
            });
            return style;
        }

        // Select interaction to spread cluster out and select features
        const selectCluster = new SelectCluster({
            pointRadius: 15,
            animate: true,
            featureStyle: function (f, res) {
                return [setFeatureStyle(f, res)]
            },
            style: function (f, res) {
                const cluster = f.get('features');
                if (cluster.length > 1) {
                    const s = [getStyle(f, res)];
                    return s;
                } else {
                    return [
                        new Style({
                            image: new CircleStyle({
                                stroke: new Stroke({ color: "rgba(0,0,192,0.5)", width: 2 }),
                                fill: new Fill({ color: "rgba(0,0,192,0.3)" }),
                                radius: 5
                            })
                        })];
                }
            }
        });
        this.map.addInteraction(selectCluster);

        selectCluster.getFeatures().on(['add'], function (e) {
            const c = e.element.get('features');
            if (c.length === 1) {
                const feature = e.element.get('features')[0];
                selectCluster.getFeatures().clear();
                t.props.dispatch(requestData('data/stream/' + feature.get('stream'))).then((stream) => {
                    const streamResult = stream;
                    const div = document.getElementsByClassName('ol-popup-content')[0];
                    let content = '';
                    popup.show(feature.getGeometry().getFirstCoordinate(), content, feature.get('hasFullAccess'));
                    t.setState({
                        stream: {
                            feature: feature,
                            streamResult: streamResult
                        }
                    }, () => {
                        const info = <MapInfoWindow stream={t.state.stream} messages={t.props.i18n} />;
                        content = ReactDOM.render(info, div)
                    })
                });
            }
        })

    }

    componentWillUnmount() {
        this.map.setTarget(null);
    }

    componentDidUpdate() {
        setTimeout(() => { this.map.updateSize(); }, 2000);
    }

    // get properties list and set map layers
    setPropertyLayers() {
        this.setState({ refreshing: true });
        this.props.dispatch(requestData('property')).then((e) => {

            const properties = e;
            let layers = [];
            // create a map layer for each property and add it to the map
            properties.forEach((property, index) => {
                const propertyLayer = this.createPropertyLayer(property, index === 0);
                this.map.addLayer(propertyLayer);
                layers.push(propertyLayer);
            });

            this.setState({
                layers: layers,
                refreshing: false,
                activeLayer: layers[0]
            });

            this.map.setTarget("sensors_map");
        });
    }


    styleFunction(feature, resolution) {

        if (this.state.activeLayer) {
            let maxFeatureCount = 0;
            const features = this.state.activeLayer.getSource().getFeatures();
            let f, radius;
            for (let i = features.length - 1; i >= 0; --i) {
                f = features[i];
                const originalFeatures = f.get('features');
                const extent = createEmpty();
                let j, jj;
                for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
                    extend(extent, originalFeatures[j].getGeometry().getExtent());
                }
                maxFeatureCount = Math.max(maxFeatureCount, jj);
                radius = (0.25 * (getWidth(extent) + getHeight(extent))) / resolution;
                f.set('radius', radius);
            }


            let style;
            const size = feature.get('features').length;

            if (size > 1) {
                style = new Style({
                    image: new CircleStyle({
                        radius: 30,
                        fill: new Fill({
                            color: [255, 153, 0, Math.min(0.8, 0.4 + size / maxFeatureCount)],
                        }),
                    }),
                    text: new Text({
                        text: size.toString() + ' ' + this.props.i18n['things'],
                        fill: new Fill({
                            color: '#fff',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.6)',
                            width: 3,
                        }),
                    }),
                });
            } else {
                const dash_caclc = 2 * Math.PI * Math.max(8, Math.min(0 * 0.75, 20)) / 4;
                const dash = [0, dash_caclc, dash_caclc, dash_caclc, dash_caclc, dash_caclc, dash_caclc];
                const originalFeature = feature.get('features')[0];
                style = new Style({
                    image: new CircleStyle({
                        fill: new Fill({ color: [153, 1, 0, 1] }),
                        radius: 12,
                        stroke: new Stroke({
                            color: "rgba(153, 1, 0 ,0.7)",
                            width: 15,
                            lineDash: dash,
                            lineCap: "butt"
                        }),
                    }),
                    text: new Text({
                        textAlign: 'center',
                        textBaseline: 'middle',
                        font: '12px Calibri,sans-serif',
                        text: originalFeature.get('value').toString(),
                        fill: new Fill({ color: '#fff' }),
                        placement: 'point',
                        overflow: 'true'
                    })
                });
            }
            return style;
        }

    }

    // create a map layer for each property
    createPropertyLayer(property, visible) {
        return new VectorLayer({
            id: property.token,
            name: property.name,
            source: new Cluster({
                distance: 50,
                source: new VectorSource()
            }),
            style: this.styleFunction,
            baseLayer: true,
            visible: visible,
            property: property
        });
    }

    activeLayer(ly) {
        this.setState({ activeLayer: ly })
    }

    periodSet(fromDate, toDate) {
        this.setState({
            from: fromDate,
            to: toDate
        })
    }

    render() {

        const { pending, status } = this.props;
        if (pending) {
            return <Loading />;
        } else if (status !== 200 && status !== '') {
            return <Error status={status} errorMsg={""} />;
        }

        const timeFilterClassName = this.state.timeFilterVisibility ? "d-flex" : "d-none d-xl-flex";

        return (
            <div className="sensors full-content">
                <div id="sensors_map" className="map h-100">
                    <Button
                        color="secondary"
                        className="time-filter-button d-lg-block d-xl-none"
                        onClick={() => {
                            this.setState({
                                timeFilterVisibility: !this.state.timeFilterVisibility
                            })
                        }}
                    >
                        <i className="fa fa-clock-o text-danger" />
                    </Button>
                    <LayerControl map={this.map} layers={this.state.layers} from={this.state.from} to={this.state.to} activeLayer={this.activeLayer} />
                    <TimeFilter onSelectPeriod={this.periodSet} className={`animated fadeIn ${timeFilterClassName}`} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    pending: state.list.property.pending,
    status: state.list.property.status,
    center: state.ui.settings.values.map.center,
    zoom: state.ui.settings.values.map.zoom,
    profile: state.profile,
    notifications: state.notifications.messages,
    i18n: state.i18n.messages
});

SensorsMap = connect(mapStateToProps)(SensorsMap);

export default SensorsMap;
