import config from  '../../package.json';

let moduleComponents = {};
if (config.modules)
	config.modules.forEach((module) => {
		try {
			moduleComponents = require(`../modules/${module}/components`);
		} catch (ex) {

		}
	});

export default {...moduleComponents};