import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip';
import { Input, Button } from 'reactstrap';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import { getData, updateData, postData } from 'core/ducks/update';
import ServiceModal from './serviceModal';
import T from 'modules/i18n';

class Service extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
			streams: props.data,
			ready: false,
			service: [],
			isModalOpen: false,
			modalAction: null,
			modalValues: null,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.streams.length !== this.state.streams.length)
			this.props.toggleButton(this.state.streams.length === 0);
	}

	fetchData = () => {
		this.setState({ready: false, streams: this.props.data});
		this.props.dispatch(
			getData(`service/thing/${this.props.thing}`)
		).then(response => {
			let streams = this.state.streams;
			const data = response.map(elem => {
				const datastream = [];
				elem.datastream.forEach(stream => {
					const { token, ...other } = stream;
					const index = streams.findIndex((e) => e.token === token);
					datastream.push({...streams[index], ...other});
					streams = [...streams.slice(0, index), ...streams.slice(index + 1)]
				});
				return {...elem, datastream};
			});
			this.setState({data, streams, ready: true});
		}).catch(err => console.warn(err));
	}

	handleAggregation = (event) => {
		const { value, checked } = event.target;
		const { service } = this.state;
		if (checked) {
			this.setState({
				service: [...service, value],
			});
		} else {
			const index = service.indexOf(value);
			this.setState({
				service: [
					...service.slice(0, index),
					...service.slice(index + 1),
				],
			});
		}
	}

	closeModal = () => {
		this.setState({
			service: [],
			isModalOpen: false,
			modalAction: null,
			modalValues: null,
		});
	}

	handleSubmit = (data) => {
		this.props.dispatch(postData('service', data))
			.then(() => {
				this.closeModal();
				this.fetchData();
			});
	}

	handleEdit = (data, token) => {
		this.props.dispatch(updateData(`service/token/${token}`, data))
			.then(() => {
				this.closeModal();
				this.fetchData();
			});
	}

	handleDelete = () => {

	}

	render() {

		const { data, ready, service, isModalOpen, streams } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<Table>
				<Thead>
					<Tr>
						<Td>Service</Td>
						<Td><T>period</T></Td>
						<Td><T>description</T></Td>
						<Td><T>property</T>{' '}(<T>unit</T>)</Td>
						<Td><T>definition</T></Td>
						<Td><T>created_field</T></Td>
					</Tr>
				</Thead>
				{ ready &&
					<Tbody>
						{ data.map(elem => (
							<React.Fragment key={`row_${elem.token}`}>
								<Tr>
									<Td>
										<i
											role="link"
											className="fa fa-pencil text-success mr-2"
											title={messages.edit || 'edit'}
											onClick={() => this.setState({
												isModalOpen: true,
												modalAction: (data) => this.handleEdit(data, elem.token),
												modalValues: {...elem},
											})}
										/>
										<i role="link" className="fa fa-trash-o text-warning mr-2" title={messages.delete || 'Delete'} onClick={this.handleDelete}/>
										<span data-for={`service_${elem.token}`} data-tip>{elem.description}</span>
										<Tooltip id={`service_${elem.token}`} delayShow={500} delayUpdate={5000} place="right">
											<h5>{elem.description}</h5>
											<dl>
												<dt>URI</dt><dd>{elem.uri}</dd>
												<dt><T>parameters</T></dt>
												{ Object.keys(elem.parameters).map(param => (
													<div key={`param_${elem.token}_${param}`} className="ml-5">
														<b>{param}</b>:{' '}{elem.parameters[param]}
													</div>
												))}
												<dt className="d-inline-block"><T>period</T></dt><dd className="d-inline-block ml-2">{elem.period}</dd><br/>
												<dt className="d-inline-block"><T>format</T></dt><dd className="d-inline-block ml-2">{elem.format}</dd>
											</dl>
										</Tooltip>
									</Td>
									<Td>{elem.period}</Td>
								</Tr>
								{ elem.datastream.map(stream => (
									<Tr key={`cell_${stream.token}`}>
										<Td/><Td/>
										<Td>{stream.description}</Td>
										<Td>{`${stream.property.symbol || stream.property.name} (${stream.unit.symbol})`}</Td>
										<Td>{stream.definition}</Td>
										<Td>{stream.created_field}</Td>
									</Tr>
								))}
							</React.Fragment>
						))}
						{ streams.length !== 0 &&
							<>
								<Tr><Td><b>[<T>without</T>]</b></Td></Tr>
								{ streams.map(stream => (
									<Tr key={`cell_${stream.token}`}>
										<Td>
											<Input
												type="checkbox"
												className="d-block mx-auto"
												value={stream.token}
												checked={service.includes(stream.token)}
												onChange={this.handleAggregation}
											/>
										</Td><Td/>
										<Td>{stream.description}</Td>
										<Td>{`${stream.property.symbol || stream.property.name} (${stream.unit.symbol})`}</Td>
										<Td>{stream.definition}</Td>
										<Td>{stream.created_field}</Td>
									</Tr>
								))}
								<Tr><Td>
									<Button
										color="success"
										disabled={service.length===0}
										onClick={() => this.setState({isModalOpen: true, modalAction: this.handleSubmit})}
									>
										<T>add</T>
									</Button>
								</Td></Tr>
							</>
						}
					</Tbody>
				}
				{ isModalOpen &&
					<ServiceModal
						isOpen={isModalOpen}
						toggle={this.closeModal}
						onSubmit={this.state.modalAction}
						getValues={() => {
							if (!this.state.modalValues)
								return null;
							const { datastream, token, ...values } = this.state.modalValues;
							return values;
						}}
						datastreams={ this.state.modalValues ?
							this.state.modalValues.datastream
								.map(elem => ({
									token: elem.token,
									label: `${elem.description} (${elem.property.symbol || elem.property.name} - ${elem.unit.symbol})`,
									definition: elem.definition,
									created_field: elem.created_field,
								}))
							:
							this.props.data
								.filter(elem => service.includes(elem.token))
								.map(elem => ({
									token: elem.token,
									label: `${elem.description} (${elem.property.symbol || elem.property.name} - ${elem.unit.symbol})`
								}))
						}
					/>
				}
			</Table>
		);
	}
}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

Service = connect(mapStateToProps)(Service);

export default Service;