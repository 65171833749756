import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading } from 'core/components';

import { getData } from 'core/ducks/update';

class RawData extends Component {

	constructor(props) {
		super(props);
		this.state = {
			url: null,
		};
	}

	componentDidMount() {
		this.setUrl(this.props.match.params.station);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.url !== this.state.url) {
			const height = document.querySelector("main").clientHeight - document.querySelector("main .breadcrumb").getBoundingClientRect().height;
			document.querySelector("iframe").height = height - 30;
		}
		if (prevProps.match.params.station !== this.props.match.params.station)
			this.setUrl(this.props.match.params.station);
	}

	setUrl = (station) => {
		const url = (station && station !== '')
			? `/chronograf?station_token=${this.props.match.params.station}`
			: '/chronograf?station_token=null';
		this.props.dispatch(getData(url, false))
			.then(r => this.setState({url: r.url}));
	}

	render() {
		const { url } = this.state;
		if (!url)
			return <Loading/>;
		return (
			<iframe title="chronograf" src={url} width="100%" height="100%" class="full-content"/>
		);
	}

}

const mapStateToProps = (state) => ({});

RawData = connect(mapStateToProps)(RawData);

export default RawData;