import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipInfo = (props) => {

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
      <Tooltip placement="top" isOpen={tooltipOpen} target={props.target} toggle={toggle}>
        {props.text}
      </Tooltip>
  );
}

export default TooltipInfo;