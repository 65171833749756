import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hierarchy } from '../model/roles';

/**
 * Renders children only if a role name is provided as
 * a property and the user has the specific role or his
 * role is higher in the hierarchy than this role.
 * (Exception: In case the role is the last in the hierarchy
 * the user role should be exact.)
 *
 * @class SecureContent
 * @extends {React.Component}
 */
class SecureContent extends React.Component {

	hasRights(role) {
		const { user } = this.props;
		if (!user || !role)
			return false;
		if (Array.isArray(role))
			return role.includes(user.role);
		if (hierarchy.indexOf(role) === -1)
			return false;
		if (hierarchy.indexOf(role) === hierarchy.length - 1)
			return role === user.role;
		return (hierarchy.indexOf(role) >= hierarchy.indexOf(user.role));
	}

	render() {
		let { role, children } = this.props;
		if (!children)
			return null;
		if (role === '')
			return children;
		if (!this.hasRights(role))
			return null;

		return children;
	}
}

SecureContent.propTypes = {
	role: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array
	]).isRequired,
	children: PropTypes.element,
};

const mapStateToProps = (state) => ({
	user: state.profile.user
});

SecureContent = connect(mapStateToProps)(SecureContent);

export default SecureContent;
