import React, {Component} from 'react';
import moment from "moment";
import {DynamicRoutes} from '../../model/routes';
import {buildPath} from 'core/model/lib/urlTools';

class MapInfoWindow extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.showDataAnalysis = this.showDataAnalysis.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(buildPath(DynamicRoutes.SensorDataAnalysis, [this.props.stream.feature.get('stream')]));
    }

    showDataAnalysis() {
        console.log('yes')
    }

    render() {
        return (
            <div className="map-info-window">
                <h6>{this.props.messages['station']}: {this.props.stream.streamResult.station.label}</h6>
                <div className='center'>
                    <h2>{this.props.stream.streamResult.property.symbol}</h2>
                    <div style={{
                        marginBottom: '-1em',
                        fontWeight: 700
                    }}>{moment(this.props.stream.feature.get('time')).format("DD/MM/YYYY, h:mm a")}</div>
                    <div
                        className='popup-aqi popup-large'>{parseFloat(this.props.stream.feature.get('value')).toFixed(2)}<span> {this.props.stream.streamResult.unit.symbol}</span></div>
                </div>
                <hr/>
                <div className='center'>
                    <h6>{this.props.messages['from date']}: {moment(this.props.stream.streamResult.from).format("DD/MM/YYYY")}{this.props.messages['showDataTimeTo']}: {moment(this.props.stream.streamResult.to).format("DD/MM/YYYY")}</h6>
                    <span className='legend-tooltip-popup'><div>min</div>
                        {parseFloat(this.props.stream.streamResult.min).toFixed(2)}</span>
                    <span className='legend-tooltip-popup'><div>max</div>
                        {parseFloat(this.props.stream.streamResult.max).toFixed(2)}</span>
                    <span className='legend-tooltip-popup'><div>avg</div>
                        {parseFloat(this.props.stream.streamResult.avg).toFixed(2)}</span>
                    <span className='legend-tooltip-popup'><div>std</div>
                        {parseFloat(this.props.stream.streamResult.std).toFixed(2)}</span>
                    <span className='legend-tooltip-popup'><div>variance</div>
                        {parseFloat(this.props.stream.streamResult.variance).toFixed(2)}</span>
                </div>
                <hr/>
                <div style={{height: '2.5em'}}/>
            </div>
        )
    }
}

export default MapInfoWindow;
