import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Card } from 'reactstrap';

import { StaticRoutes } from 'sensors/model/routes';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import { ReactComponent as MapSVG } from '../img/map.svg';
import { ReactComponent as DataAnalysisSVG } from '../img/data_analysis.svg';
import { ReactComponent as ForecastSVG } from '../img/forecast.svg';
import '../style/dashboard.scss';
import T from 'modules/i18n';

class Map extends Component {

	render() {

		return (
			<SecureContent role={roles.REVIEWER}>
				<Card className="dashboard-card">
					<span className="title"><T>map</T></span>
					<Link to={StaticRoutes.SensorsMap} title={this.props.intl.messages['map']}>
						<MapSVG/>
					</Link>
				</Card>
			</SecureContent>
		);
	}

}

export const DashboardMap = injectIntl(Map);

class DataAnalysis extends Component {

	render() {

		return (
			<SecureContent role={roles.REVIEWER}>
				<Card className="dashboard-card">
					<span className="title"><T>Data Analysis</T></span>
					<Link to={StaticRoutes.DataAnalysis} title={this.props.intl.messages['Data Analysis']}>
						<DataAnalysisSVG/>
					</Link>
				</Card>
			</SecureContent>
		);
	}

}

export const DashboardDataAnalysis = injectIntl(DataAnalysis)

export class Forecast extends Component {

	render() {

		return (
			<SecureContent role={roles.REVIEWER}>
				<Card className="dashboard-card">
					<span className="title"><T>forecasts</T></span>
					<Link to={StaticRoutes.Cams} title={this.props.intl.messages['forecasts']}>
						<ForecastSVG/>
					</Link>
				</Card>
			</SecureContent>
		);
	}

}

export const DashboardForecast = injectIntl(Forecast)
