import React, { Component } from 'react';
import {
	Row, Col, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import proj4 from 'proj4';
import L from 'leaflet';
import VisibilitySensor from 'react-visibility-sensor';

import T from 'modules/i18n';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class Map extends Component {

	constructor(props) {
		super(props);
		proj4.defs('EPSG:2100', "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-199.87,74.79,246.62,0,0,0,0 +units=m +no_defs");
		this.precision = (props.options && props.options.EPSG && props.options.EPSG === 'EPSG:4326') ? 8 : 3;

		const map = this.initialize(props);
		this.state = {
			rule: {},
			map,
			clicked: false,
		};

		this.mapRef = React.createRef();

		this.handleCoordinatesChange = this.handleCoordinatesChange.bind(this);
		this.handleNewCoordinates = this.handleNewCoordinates.bind(this);
		this.handleCRSChange = this.handleCRSChange.bind(this);
		this.handleMapClick = this.handleMapClick.bind(this);
		this.initialize = this.initialize.bind(this);
		this.changeCoordinates = this.changeCoordinates.bind(this);
	}

	initialize(props) {
		let coordinates = props.value ? props.value.split(';') : undefined;
		if (coordinates) {
			coordinates[0] = parseFloat(Number.parseFloat(coordinates[0]).toFixed(this.precision));
			coordinates[1] = parseFloat(Number.parseFloat(coordinates[1]).toFixed(this.precision));
		}
		const crs = props.options.EPSG ? props.options.EPSG : 'EPSG:2100';
		const projected = coordinates ? proj4(crs, 'EPSG:4326', [coordinates[1], coordinates[0]]) : ['', ''];
		const position = props.value
			? [projected[1], projected[0]]
			: (props.options.position ? props.options.position : [40.3284628, 21.608216]);
		let map = {
			lat: coordinates ? coordinates[0] : '',
			lon: coordinates ? coordinates[1] : '',
			x: coordinates ? coordinates[1] : '',
			y: coordinates ? coordinates[0] : '',
			marker: projected,
			position,
			zoom: props.options.zoom || 7,
			crs,
		};

		return map;
	}

	handleCoordinatesChange(event, cord) {
		let target = event.target;

		let value = parseFloat(Number.parseFloat(target.value).toFixed(this.precision));

		const lng = cord==='lon' ? value : this.state.map.lon;
		const lat = cord==='lat' ? value : this.state.map.lat;
		value = this.handleNewCoordinates({lat, lng});

		this.props.onChange({target: {name: target.name, value}});
	}

	handleNewCoordinates(latlng) {
		const { crs } = this.state.map;
		let x, y, marker;
		let {lat, lng} = latlng;
		lng = parseFloat(Number.parseFloat(lng).toFixed(3));
		lat = parseFloat(Number.parseFloat(lat).toFixed(3));

		if ( crs === 'EPSG:2100' ) {
			x = lng;
			y = lat;
			marker = (x!=='' && y!=='') ? proj4('EPSG:2100', 'EPSG:4326', [x, y]) : ['', ''];
		} else {
			[x, y] = (lng!=='' && lat!=='') ? proj4(crs, 'EPSG:2100', [lng, lat]) : ['', ''];
			x = parseFloat(Number.parseFloat(x).toFixed(3));
			y = parseFloat(Number.parseFloat(y).toFixed(3));
			marker = crs==='EPSG:4326' ? [lng, lat] : ((lng!=='' && lat!=='') ? proj4(crs, 'EPSG:4326', [lng, lat]) : ['', '']);
		}

		this.setState({map: {
			...this.state.map,
			lon: lng,
			lat,
			x,
			y,
			marker
		}});

		return y + ';' + x;
	}

	handleCRSChange(event) {
		let target = event.target;
		let map = this.state.map;
		let lon, lat;
		this.precision = target.value === 'EPSG:4326' ? 8 : 3;
		if ( map.lon !== '' && map.lat !== '') {
			[lon, lat] = proj4(map.crs, target.value, [map.lon, map.lat]);
			lon = parseFloat(Number.parseFloat(lon).toFixed(this.precision));
			lat = parseFloat(Number.parseFloat(lat).toFixed(this.precision));
		} else {
			lon = lat = '';
		}
		this.setState({map: {
			...map,
			crs: target.value,
			lon,
			lat
		}});
	}

	handleMapClick(event) {
		this.setState({clicked: true});
		this.changeCoordinates(event.latlng);
	}

	changeCoordinates(latlng) {
		if (this.props.readOnly)
			return;
		const { crs } = this.state.map;
		let { lat, lng } = latlng;
		[lng, lat] = crs!=='EPSG:4326' ? proj4('EPSG:4326', crs, [lng, lat]) : [lng, lat];
		const value = this.handleNewCoordinates({lat, lng});
		this.props.onChange({target: {name: this.props.name, value, latlng}});
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.geoCoding) !== JSON.stringify(this.props.geoCoding)) {
			this.changeCoordinates(this.props.geoCoding);
		}
	}

	render() {
		const { name, readOnly, required } = this.props;
		const { lat, lon, position, zoom, crs, marker } = this.state.map;
		let markerPosition = (marker[0] !== '' && marker[1] !== '') ? [marker[1], marker[0]] : undefined;
		const showCoordinates = (typeof this.props.showCoordinates !== 'undefined') ? this.props.showCoordinates : true;

		return (
			<FormGroup>
				<Row>
					{ showCoordinates &&
						<Col xs="12" md="6" lg="4">
							<FormGroup>
								<InputGroup>
									<InputGroupAddon addonType="prepend">
										<InputGroupText><T>projection system</T></InputGroupText>
									</InputGroupAddon>
									<Input id={`${name}_proj`} type="select" name={name} onChange={this.handleCRSChange} value={crs}>
										<option value="EPSG:3857">Google Map</option>
										<option value="EPSG:4326">WGS84</option>
										<option value="EPSG:2100">ΕΓΣΑ87</option>
									</Input>
								</InputGroup>
							</FormGroup>
							<FormGroup tag="fieldset">
								<FormGroup>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText><T>lat</T>/y</InputGroupText>
										</InputGroupAddon>
										<Input
											id={`${name}_lat`}
											type="number"
											min={crs==='EPSG:4326' ? 33.230 : (crs==='EPSG:2100' ? 3691163.514 : 3925872.561)}
											max={crs==='EPSG:4326' ? 41.770 : (crs==='EPSG:2100' ? 4641211.322 : 5126588.574)}
											step={crs==='EPSG:4326' ? 0.00000001 : 0.001}
											required={required}
											name={name}
											value={lat}
											onChange={(event) => this.handleCoordinatesChange(event, 'lat')}
											readOnly={readOnly}
										/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<InputGroup>
										<InputGroupAddon addonType="prepend">
											<InputGroupText><T>lon</T>/x</InputGroupText>
										</InputGroupAddon>
										<Input
											id={`${name}_lon`}
											type="number"
											min={crs==='EPSG:4326' ? 18.270 : (crs==='EPSG:2100' ? -34387.67 : 2033807.096)}
											max={crs==='EPSG:4326' ? 29.970 : (crs==='EPSG:2100' ? 1056496.843 : 3336245.136)}
											step={crs==='EPSG:4326' ? 0.00000001 : 0.001}
											required={required}
											name={name}
											value={lon}
											onChange={(event) => this.handleCoordinatesChange(event, 'lon')}
											readOnly={readOnly}
										/>
									</InputGroup>
								</FormGroup>
							</FormGroup>
						</Col>
					}
					<Col sm="12" md={showCoordinates ? 6 : 12} lg={showCoordinates ? 8 : 12}>
						<VisibilitySensor onChange={() => {this.mapRef.current.leafletElement.invalidateSize()}}>
							<LeafletMap
								ref={this.mapRef}
								style={{width: 100 + '%', height: 300 + 'px'}}
								center={!this.state.clicked ? (markerPosition || position) : undefined}
								zoom={!this.state.clicked ? zoom : undefined}
								onClick={(e) => this.handleMapClick(e, name)}
							>
								<TileLayer
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
									attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
								/>
								{ markerPosition ?
									<Marker position={markerPosition} style={{width: 10 + 'px', height: 10 + 'px'}}/>
									:
									null
								}
							</LeafletMap>
						</VisibilitySensor>
					</Col>
				</Row>
			</FormGroup>
		);
	}

}

Map.propTypes = {
	value: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	options: PropTypes.object,
	required: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool.isRequired,
	geoCoding: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	showCoordinates: PropTypes.bool,
}

export default Map;
