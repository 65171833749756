import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	Modal,
	ModalHeader,
	ModalBody
} from 'reactstrap';

import T from 'modules/i18n';
import LoginForm from '../forms/loginForm';
import { modalLogin } from 'core/ducks/profile';

class LoginModal extends Component {

	render() {

		return (
			<Modal isOpen={this.props.isOpen} className="modal-md">
				<ModalHeader toggle={this.props.toggleModal}><T>login</T></ModalHeader>
				<ModalBody>
					<LoginForm submit={this.props.login} notifications={this.props.notifications} modal={true}/>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	notifications: state.notifications.messages,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	login: modalLogin
}, dispatch);

LoginModal = connect(mapStateToProps, mapDispatchToProps)(LoginModal);

export default LoginModal;
