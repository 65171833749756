import React, { Component } from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown
} from 'reactstrap';

import T from 'modules/i18n';

class DropDown extends Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	render() {
		const values = this.props.values;
		const id = this.props.data_id;
		const dispatch = this.props.dispatch;
		const choices = Object.keys(values).map((index) => {
			const value = values[index];
			return (
				<DropdownItem
					key={"dd_choice"+id+index}
					onClick={() => {
						const dispatch_params = this.props.dispatch_params;
						dispatch_params[this.props.item] = value;
						dispatch(dispatch_params)
					}}
				>
					<T>{value}</T>
				</DropdownItem>);
		});
		return (
			<Dropdown className="inline-dd" direction="left" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
				<DropdownToggle caret size="sm" className="no-outline btn-xsm"/>
				<DropdownMenu right>{choices}</DropdownMenu>
			</Dropdown>
		);
	}
}

export default DropDown;
