import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Input, Label, Button, ButtonGroup
} from 'reactstrap';

import { getData, postData } from 'core/ducks/update';
import { getParameters } from 'core/model/lib/urlTools';
import { default as allRoutes } from '../../model/routes';
import Filters from 'sensors-admin/views/forms/filters';
import T from 'modules/i18n';

class Alerts extends Component {

	constructor(props) {
		super(props);
		const initialValues = {datastream: '', threshold: '', relation: 'ABOVE', duration: 5, period: 24, send_stop_email: true};
		this.initialState = {
			label: '',
			isFormEnabled: false,
			values: {...initialValues},
			properties: [],
		}
		this.state = {
			...this.initialState
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSumit = this.handleFormSumit.bind(this);
	}

	componentDidMount() {
		const params = getParameters(this.props.location.pathname, allRoutes.routes);
		if (params.station)
			this.props.dispatch(
				getData(`thing/token/${params.station}`)
			).then(r => {
				this.setState({label: {value: params.station, label: r.label}})
			});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.label !== this.state.label)
			this.getProperties(this.state.label)
	}

	getProperties = (label) => {
		if (label !== '') {
			this.props.dispatch(
				getData(`info/token/${label.value}`)
			).then((r) => {
				const properties = r.datastreams.map((e) => ({value: e.token, label: e.property.symbol, unit: e.unit.symbol}))
				this.setState({isFormEnabled: true, properties});
			});
		} else {
			this.setState({isFormEnabled: false, properties: []})
		}
	}

	handleInputChange(event) {
		const { name, type, value, checked } = event.target;
		this.setState({
			values: {...this.state.values, [name]: type==='checkbox' ? checked : value}
		});
	}

	handleFormSumit(event) {
		event.preventDefault();
		this.props.dispatch(
			postData('alert', this.state.values)
		).then(() => {
			this.setState({...this.initialState});
			this.props.context.set({needsRefresh: true});
		});
	}

	render() {

		const { label, properties, values } = this.state;
		const { messages } = this.props.i18n || {messages: {}};

		return (
			<>
				<Nav tabs>
					<NavItem>
						<NavLink className='active text-info p-2'>
							<T>add</T>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={0} className="scroller mx-2 mt-2">
					<TabPane tabId={0}>
						<Filters label={label} handleSelect={(label) => this.setState({label})} startOpen={false} />
						{ this.state.isFormEnabled &&
							<Form onSubmit={this.handleFormSumit} className="my-3">
								<h5>{label.label}</h5>
								<FormGroup tag="fieldset" className="border-top border-bottom pb-3">
									<legend style={{fontSize: 'initial', width: 'initial'}} className="px-2 ml-3"><T>condition</T></legend>
									<FormGroup className="w-100">
										<Label>
											<T>property</T>
											<Input required type="select" value={values.datastream} name="datastream" onChange={this.handleInputChange}>
												<option value="">Select property</option>
												{properties.map(property => (
													<option key={`option_${property.value}`} value={property.value}>{`${property.label} (${property.unit})`}</option>
												))}
											</Input>
										</Label>
									</FormGroup>
									<FormGroup className="w-100">
										<Input required type="select" value={values.relation} className="text-right" name="relation" onChange={this.handleInputChange}>
											<option value="ABOVE">{messages.above.toUpperCase()}</option>
											<option value="BELOW">{messages.below.toUpperCase()}</option>
										</Input>
									</FormGroup>
									<FormGroup inline className="w-100 d-flex">
										<Label style={{margin: '0 auto'}}>
											<T lettercase="lower">threshold</T>
											<Input required type="number" style={{width: '5rem'}} className="d-inline-block mx-2" value={values.threshold} name="threshold" onChange={this.handleInputChange}/>
										</Label>
									</FormGroup>
									<FormGroup className="w-100 d-flex">
										<Label style={{margin: '0 auto'}}>
											<T>for</T>{' '}
											<Input type="number" min="1" style={{width: '5rem'}} className="d-inline-block mx-2" value={values.duration} name="duration" onChange={this.handleInputChange}/>
											<span>(s)</span>.
										</Label>
									</FormGroup>
								</FormGroup>
								<FormGroup tag="fieldset">
									<FormGroup>
										<Label>
											<T>send emails when active every</T>{' '}
											<Input type="number" min="1" value={values.period} name="period" onChange={this.handleInputChange} placeholder="(hr)"/>
										</Label>
									</FormGroup>
									<FormGroup check>
										<Label check>
											<Input type="checkbox" checked={values.send_stop_email} name="send_stop_email" onChange={this.handleInputChange}/>{' '}
											<T lettercase="lower">send email when alert stops</T>
										</Label>
									</FormGroup>
								</FormGroup>
								<ButtonGroup>
									<Button type="submit" className="m-1"><T>submit</T></Button>
									<Button type="button" className="m-1" color="warning" onClick={() => this.setState({...this.initialState})}><T>cancel</T></Button>
								</ButtonGroup>
							</Form>
						}
					</TabPane>
				</TabContent>
			</>
		);
	}

}

const mapStateToProps = (state) => ({
	i18n: state.i18n,
});

Alerts = connect(mapStateToProps)(Alerts);

export default Alerts;
