import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';

class FilterGroup extends Component {

	render() {
		return (
			<FormGroup tag="fieldset" {...this.props}>
				{ this.props.legend
					&& <legend>{this.props.legend}</legend>
				}
				{this.props.children}
			</FormGroup>
		);
	}
}

FilterGroup.propTypes = {
	legend: PropTypes.string,
};

FilterGroup.displayName = 'FilterGroup';

export default FilterGroup;
