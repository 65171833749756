import React, { Component } from 'react';
import {
	Row,
	Col,
	CardHeader,
} from 'reactstrap';

class Title extends Component {

	render() {
		return (
			<CardHeader>
				<Row>
					<Col>
						<i className="fa fa-align-justify"></i> {this.props.children}
					</Col>
				</Row>
			</CardHeader>
		);
	}
}

Title.displayName = 'Title';

export default Title;
