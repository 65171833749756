import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input as ReactstrapInput } from 'reactstrap';
import uniqid from 'uniqid';

class Input extends Component {

	constructor(props) {
		super(props);
		this.state = {
			valid: undefined,
			invalid: undefined,
			value: props.value,
			otherForSelectValue: props.value,
			otherInputEnabled: false,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		this.setState({value: this.props.value});
		this.validate(this.props.value, this.props.pattern);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.valid !== this.props.valid)
			this.validate(this.props.value, this.props.pattern);
	}

	handleChange(event) {
		if (typeof this.props.onChange === 'function')
			this.props.onChange(event);
		this.setState({value: event.target.value});
		this.validate(event.target.value, this.props.pattern);
	}

	validate(value, pattern) {
		if (this.props.valid === false) {
			this.setState({
				valid: false,
				invalid: true,
			});
			return;
		} else if (this.props.valid === true && this.state.invalid) {
			this.setState({
				invalid: false,
			});
		}
		if (!pattern)
			return;

		let regex = new RegExp('^' + pattern + '$');
		const valid = value.trim() === '' ? undefined : regex.test(value);
		const invalid = value.trim() === '' ? undefined : !valid;
		this.setState({
			valid,
			invalid
		});
	}

	render() {

		const { pattern, onChange, options, textForOther, otherType, value, ...otherProps } = this.props;

		if (this.props.type === 'select' && options)
			return (
				<ReactstrapInput
					{...otherProps}
					type="select"
					value={value}
					valid={this.state.valid}
					invalid={this.state.invalid}
					onChange={this.handleChange}
				>
					{ Object.keys(options).map(key => (
						<option key={uniqid()} value={key}>{options[key]}</option>
					))}
				</ReactstrapInput>
			);

		return (
			<ReactstrapInput
				{...otherProps}
				value={value}
				valid={this.state.valid}
				invalid={this.state.invalid}
				onChange={this.handleChange}
			/>
		);
	}
}

Input.propTypes = {
	value: PropTypes.any.isRequired,
	pattern: PropTypes.string,
	onChange: PropTypes.func,
	valid: PropTypes.bool,
	options: PropTypes.object,
};

export default Input;
