import * as roles from 'core/model/roles';
/**
 * Static routes
 */

// Admin
const UsersList = '/admin/users/list';

export const StaticRoutes = { UsersList };

/**
 * Dynamic routes
 */
const UsersRole =  UsersList + '/role/:role';
const UsersStatus = UsersList + '/status/:status';
const UsersRoleStatus =  UsersList + '/(role)?/:role?/(status)?/:status?';

export const DynamicRoutes = {
	UsersRole,
	UsersStatus,
	UsersRoleStatus,
};

export const routes = [
	{
		url: UsersRoleStatus,
		name: 'list',
		component: 'Users',
		role: roles.REVIEWER,
		exact: true,
	},
];

const combined = {StaticRoutes, DynamicRoutes, routes};

export default combined;
