import * as roles from 'core/model/roles';

const Alerts = '/admin/alerts';

export const StaticRoutes = {
	Alerts,
};

const routes = [
	{
		url: Alerts,
		name: 'alert',
		component: 'Alerts',
		role: roles.REVIEWER,
		contextComponent: 'AlertsSidebar',
	},
];

const combined = {StaticRoutes, routes};

export default combined;
