import React, { Component } from 'react';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { requestData } from "core/ducks/list";
import GeoJSON from 'ol/format/GeoJSON';
import { transformExtent } from 'ol/proj';
import { Loading, Error } from 'core/components';

class LayerControl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            layers: []
        };
        this.changeVisibility = this.changeVisibility.bind(this);
        this.setData = this.setData.bind(this);
        this.props.map.on('moveend', this.debounce((e) => {
            this.setData();
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.state.layers.length === 0 && this.props.layers !== null) {
            this.setState({
                layers: this.props.layers
            })
            setTimeout(() => { this.setData(); }, 1000);
        }

        if(this.props.from !== prevProps.from || this.props.to !== prevProps.to){
            
            setTimeout(() => { this.setData(); }, 1000);
        }
    }

    componentWillUnmount() {
        this.setState({
            layers: []
        });
        this.props.map.un('moveend', this.debounce((e) => {
            this.setData();
        }));
    }

    changeVisibility(layer) {
        this.state.layers.forEach((ly) => {
            ly.setVisible(false);
        })
        layer.setVisible(true);
        this.setState({
            layers: this.state.layers
        });
        this.setData();
        this.props.activeLayer(layer);
    }

    setData() {

        let layer = null;
        this.state.layers.forEach((ly) => {
            if (ly.type === 'VECTOR' && ly.getVisible()) {
                layer = ly;
            }
        });

        const bbox4326 = transformExtent(this.props.map.getView().calculateExtent(), 'EPSG:3857', 'EPSG:4326').join();

        let url = `data/bbox/${bbox4326},4326/property/${layer.get('property').symbol}`;

        if(this.props.from !== '' && this.props.to !== ''){
            url = `data/bbox/${bbox4326},4326/property/${layer.get('property').symbol}/from/${this.props.from}/to/${this.props.to}`
        }

        this.props.dispatch(requestData('propertyFeatureCollection', url)).then((sens) => {

            const geojsonObject = sens;

            this.state.layers.forEach((e) => {
                if (e.type === 'VECTOR') {
                    e.getSource().getSource().clear();
                }
            });

            const format = new GeoJSON({
                defaultDataProjection: 'EPSG:4326'
            });

            if (geojsonObject.features) {
                const features = format.readFeatures(geojsonObject, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                try {
                    layer.getSource().getSource().addFeatures(features);
                } catch (e) {

                }
            }

        });
    }

    debounce(func) {
        let timer;
        return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, 500, event);
        };
    }

    render() {

        const { pending, status } = this.props;
        if (pending) {
            return <Loading />;
        } else if (status !== 200 && status !== '') {
            return <Error status={status} errorMsg={""} />;
        }

        return (
            <div className="ol-layerswitcher-popup layer-control-container">
                <ul className="panel">
                    {
                        this.state.layers.length > 0 ?
                            this.state.layers.map((layer, index) => {
                                const showLyClass = layer.getVisible() ? 'ol-layer-hidden ol-visible' : 'ol-layer-hidden'
                                return <li key={`layer-control-li-${index}`} className={showLyClass}
                                    onClick={(e) => {
                                        this.changeVisibility(layer)
                                    }
                                    }>
                                    {layer.get('property').symbol}
                                    {
                                        layer.get('property').definition ?
                                            <div className="marquee ol-layerswitcher-popup-definition">
                                                <span><span>    {layer.get('property').definition}  </span></span>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        layer.get('property').description ?
                                            <p className="ol-layerswitcher-popup-description">{layer.get('property').description}</p>
                                            :
                                            null
                                    }
                                    <hr />
                                </li>
                            })
                            :
                            null
                    }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    pending: state.list.propertyFeatureCollection.pending,
    refreshing: state.list.propertyFeatureCollection.refreshing,
    status: state.list.propertyFeatureCollection.status,
    data: state.list.propertyFeatureCollection.data
});

LayerControl = connect(mapStateToProps)(injectIntl(LayerControl));

export default LayerControl;
