import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import { postData } from 'core/ducks/update';
import { pushNotification } from 'core/ducks/notifications';
import T from 'modules/i18n';
import RegistrationForm from '../forms/registrationForm';

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			submitted: false,
			recaptchaValue: '',
		};
	}

	handleSubmit = (data) => {
		const { dispatch } = this.props;
		dispatch(postData('registration', {...data, recaptcha: this.state.recaptchaValue}, false));
	}

	componentDidUpdate(prevProps) {
		if (prevProps.post_pending && !this.props.post_pending) {
			const { post_status, dispatch } = this.props;
			if (post_status === 200) {
				dispatch(pushNotification({
					body: "successful registration",
					type: "success",
					duration: 3
				}));
				this.setState({submitted: true});
			} else if (post_status === 409) {
				dispatch(pushNotification({
					body: "username exists",
					type: "warning",
					duration: 3
				}));
			}
		}
	}

	render() {
		let message = this.props.notifications[this.props.notifications.length-1];
		console.log(this.props.locale);

		return (
			<div className="app-container flex-row align-items-center">
				<Container>
					<Row className="justify-content-center">
						<Card className="mx-4">
							<CardBody className="p-4">
								<h1><T>register</T></h1>
								<p className="text-muted"><T>create your account</T></p>
								{ message &&
									<p className={message.type}><T>{message.body}</T></p>
								}
								<RegistrationForm submitted={this.state.submitted} onSubmit={this.handleSubmit}>
									<Row>
										<Col className="m-0 py-0 pr-3 text-muted text-center">
											{ this.props.isRecaptchaEnabled &&
												<ReCAPTCHA
													key={`recaptcha_${this.props.locale}`}
													sitekey={this.props.recaptchaKey}
													onChange={(recaptchaValue) => this.setState({recaptchaValue})}
													hl={this.props.locale}
												/>
											}
										</Col>
									</Row>
									<Row>
										<Col sm="3">
											<Button type="button" color="primary" block onClick={() => {this.props.history.goBack()}}><i className="fa fa-backward"/> <T>back</T></Button>
										</Col>
										<Col sm="9">
											<Button disabled={this.state.submitted || this.state.recaptchaValue===''} type="submit" color="success" block><T>create account</T></Button>
										</Col>
									</Row>
								</RegistrationForm>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	notifications: state.notifications.messages,
	post_pending: state.update.sending,
	post_status: state.update.status,
	isRecaptchaEnabled: state.ui.settings.values.isRecaptchaEnabled,
	recaptchaKey: state.ui.settings.values.recaptchaSiteKey,
	locale: state.i18n.locale,
});

Register = connect(mapStateToProps)(Register);

export default Register;
