import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Modal, ModalBody, ModalHeader, Row, Col, Badge } from 'reactstrap';
import { FormattedDate, FormattedTime } from 'react-intl';

import { requestData } from 'core/ducks/list';
import { toggleModal } from 'core/ducks/ui/modal';
import T from 'modules/i18n';

class ViewUser extends Component {

	constructor(props) {
		super(props);
		this.state = {
			values: {},
			pending: true,
		};
	}

	componentDidMount() {
		if (!this.props.data && this.props.uuid) {
			this.props.dispatch( requestData('user', `users/uuid/${this.props.uuid}`) ).then(() => {
				if (this.props.list.user.status === 200)
					this.setState({
						values: this.props.list.user.data,
						pending: false
					});
			});
		} else if (this.props.data) {
			this.setState({
				values: this.props.data,
				pending: false,
			})
		}
	}

	render() {

		const { values, pending } = this.state;

		if (pending)
			return null;

		return (
			<Modal isOpen={this.props.isOpen} className="modal-md">
				<ModalHeader toggle={() => this.props.dispatch(toggleModal())}>
					<T>user details</T>:<span className="font-weight-bold" >{' ' + values.username}</span>
				</ModalHeader>
				<ModalBody>
					<Badge color={values.active ? 'success' : 'danger'} style={{position: 'absolute', right: '2rem'}} className="p-2">
						<T>{values.active ? 'active' : 'inactive'}</T>
					</Badge>
					<Row>
						<Col className="text-center font-italic" style={{fontSize: '130%'}}>{(values.firstname || '') + ' ' + (values.lastname || '')}</Col>
					</Row>
					{ values.address &&
						<Row>
							<Col className="text-center">{values.address.split('+').join(' ')}</Col>
						</Row>
					}
					<Row>
						<Col>
							<Row>
								<Col className="text-center m-0 p-0">
									{ values.email &&
										<a href={`mailto:${values.email}`}><i className="fa fa-envelope" style={{fontSize: '120%'}} />{' ' + values.email}</a>
									}
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col className="text-right pt-2" xs="5"><T>{values.role}</T></Col>
						<Col xs="7" className="pt-2">
							<T>from</T>{' '}
							<FormattedDate
								value={values.created}
								year='numeric'
								month='long'
								day='numeric'
							/>
						</Col>
					</Row>
					<Row>
						<Col xs="5"/>
						<Col className="text-muted text-lowercase" xs="7">
							<T>last_login</T>{' '}
							<FormattedDate value={values.last_login} />{' '}
							<FormattedTime value={values.last_login} />
						</Col>
					</Row>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	isOpen: state.ui.modal.modalOpen,
	list: state.list,
});

ViewUser.propTypes = {
	data: PropTypes.object,
	uuid: PropTypes.string,
};

ViewUser = connect(mapStateToProps)(ViewUser);

export default ViewUser;
