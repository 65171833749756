import React, { Component } from 'react';

class Tr extends Component {

	render() {

		let { className, ...otherProps } = this.props;

		className = className ? 'tr ' + className : 'tr';

		return (
			<div className={className} {...otherProps}>
				{this.props.children}
			</div>
		);
	}
}

export default Tr;
