import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import '../../style/timeFilter.scss';

class TimeFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            from: '',
            to: new Date().toISOString().split("T")[0]
        };

        this.setData = this.setData.bind(this);
    }

    setData(action){
        if (action === 'show-all'){
            this.setState({
                from: '',
                to: new Date().toISOString().split("T")[0]
            },()=>this.props.onSelectPeriod(this.state.from, this.state.to))
        }else{
            this.props.onSelectPeriod(this.state.from, this.state.to)
        }        
    }

    render() {

        let className = 'time-filter form-inline';
        className += this.props.className ? ` ${this.props.className}` : '';

        return (
            <Row form className={className}>
                <Col xl="3" md="12" className="py-1 mr-0 pr-1 text-center">
                    {this.props.i18n['showData']}
                </Col>
                <Col xl="3" md="12" className="py-1 mx-0 pl-1 pr-2">
                    <FormGroup inline className="mr-0">
                        <Label>
                            <span className="pr-1">{this.props.i18n['from']}</span>
                            <Input type="date" name="fromDate" bsSize="sm"
                                max={this.state.to}
                                value={this.state.from}
                                onChange={(e) => this.setState({ from: e.target.value })}
                            />
                        </Label>
                    </FormGroup>
                </Col>
                <Col xl="3" md="12" className="py-1 mx-0 px-0">
                    <FormGroup inline>
                        <Label>
                            <span className="pl-0 pr-1">{this.props.i18n['to']}</span>
                            <Input type="date" name="toDate" bsSize="sm"
                                min={this.state.from}
                                max={new Date().toISOString().split("T")[0]}
                                value={this.state.to}
                                onChange={(e) => this.setState({ to: e.target.value })}
                            />
                        </Label>
                    </FormGroup>
                </Col>
                <Col xl="3" md="12" className="py-1 text-center">
                    <Button color="success" onClick={() => this.setData('set-period')} title={this.props.i18n['show']} className="mx-1">
                        <i className="fa fa-search" aria-hidden="true" />
                    </Button>
                    <Button color="danger" onClick={() => this.setData('show-all')} title={this.props.i18n['reset']} className="mx-1">
                        <i className="fa fa-ban" aria-hidden="true" />
                    </Button>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    i18n: state.i18n.messages
});

TimeFilter = connect(mapStateToProps)(TimeFilter);

export default TimeFilter;
