import {Control} from 'ol/control';
import './legend.css';

let ol_legend_info = (function(Control) {
    function LegendInfoControl(opt_options) {
        let options = opt_options || {};

        let element = document.createElement('div');
        element.setAttribute("id", "ol-legendInfoDiv");
        element.className = 'ol-legend-info ol-unselectable ol-control';

        Control.call(this, {
            element: element,
            target: options.target
        });
    }

    if ( Control ) LegendInfoControl.__proto__ = Control;
    LegendInfoControl.prototype = Object.create( Control && Control.prototype );
    LegendInfoControl.prototype.constructor = LegendInfoControl;

    return LegendInfoControl;
}(Control));

ol_legend_info.prototype.set = function(map) {

    let image_source = null;

    map.getLayerGroup().on('change', function (e) {
        const layers = e.target.get('layers').getArray();
        layers.forEach(function (item) {
            if(item.get('baseLayer') && item.get('visible')){
                // let layer_name = item.get('source').getParams().LAYERS;
                // let layer_url = item.get('source').url_;

                // image_source = layer_url + '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=' + layer_name;

                image_source = item.get('legend');

                let div = document.getElementById('ol-legendInfoDiv');
                div.innerHTML = '<img src="' + image_source + '" alt="Legend" >';
                div.classList.add('ol-legend-info-show');
            }
        });
    });



}


export default ol_legend_info
