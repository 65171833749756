import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input as Select } from 'reactstrap';
import { Input } from 'core/components';

class GeneralInput extends Component {

	render() {
		const { options, ...other } = this.props;
		let selectOptions = options
			? Object.values(options).map(value => <option key={value} value={value}>{value}</option>)
			: undefined;

		if (selectOptions)
			return (
				<Select
					{...other}
					autoComplete="off"
					disabled={this.props.readOnly || undefined}
				>
					<option value="">-----</option>
					{selectOptions}
				</Select>
			);

		return (
			<Input
				{...other}
				autoComplete="off"
				disabled={this.props.readOnly || undefined}
			/>
		);
	}
}

GeneralInput.propTypes = {
	id: PropTypes.string,
	type: PropTypes.string.isRequired,
	required: PropTypes.bool,
	maxLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]).isRequired,
	onChange: PropTypes.func,
	valid: PropTypes.string,
	readOnly: PropTypes.bool,
	options: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
};

export default GeneralInput;
