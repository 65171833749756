import React, { Component } from 'react';
import { Row, Col, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Map as LeafletMap, TileLayer, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import '../scss/style.scss';

const getLayerType = (layer) => {
	if (layer instanceof L.Marker)
		return 'point';
	if ((layer instanceof L.Polyline) && ! (layer instanceof L.Polygon))
		return 'line';
	if (layer instanceof L.Polygon)
		return 'polygon';
};

class PolygonInput extends Component {

	constructor(props) {
		super(props);

		this.editControlRef = React.createRef();

		this.state = {
			polygon: {},
			line: {},
			point: {},
			disabled: props.disabled,
			drawing: false,
		};
	}

	_onEdited = (e) => {
		if (this.props.returnGeoJSON) return;
		let changes = {
			'polygon': {},
			'line': {},
			'point': {},
		};
		e.layers.eachLayer(layer => {
			const scope = getLayerType(layer);
			changes[scope][layer._leaflet_id] = layer._latlngs;
		});
		this.setState({
			polygon: {...this.state.polygon, ...changes['polygon']},
			line: {...this.state.line, ...changes['line']},
			point: {...this.state.point, ...changes['point']},
		}, this._onChange);
	}

	_onCreated = (e) => {
		let { layer } = e;
		const scope = getLayerType(layer);
		this.setState({
			[scope]: {...this.state[scope], [layer._leaflet_id]: layer._latlngs || layer._latlng}
		}, this._onChange);
	}

	_onDeleted = (e) => {
		if (this.props.returnGeoJSON) return;
		let ids = {};
		e.layers.eachLayer(layer => {
			ids[layer._leaflet_id] = true;
		});
		let polygon, line, point;
		['polygon', 'line', 'point'].forEach(feature => {
			let newList = Object.keys(this.state[feature])
				.filter(id => !ids[id])
				.reduce((obj, id) => ({
					...obj,
					[id]: this.state[feature][id]
				}), {});
			if (feature === 'polygon') {
				polygon = newList;
			} else if (feature === 'line') {
				line = newList;
			} else {
				point = newList;
			}
		});

		this.setState({
			polygon,
			line,
			point,
		}, this._onChange);
	}

	_onChange = () => {

		if (!this._editableFG)
			return;
		const value = this.props.returnGeoJSON ?
			this._editableFG.leafletElement.toGeoJSON()
			:
			[
				...Object.values(this.state.polygon),
				...Object.values(this.state.line),
				...Object.values(this.state.point),
			];
		this.props.onChange({
			target: {
				name: this.props.name,
				value
			}
		});
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.disabled && this.props.disabled) {
			this.setState({
				polygon: {}
			});
			this.removeAllLayers();
		}

		if (prevProps.disabled && !this.props.disabled)
			this.setState({disabled: this.props.disabled});

		if (prevProps.geojson !== this.props.geojson) {
			this.removeAllLayers();
			this._drawGeoJSON(this._editableFG);
			if (!this.state.drawing) {
				this._onChange();
			} else {
				this.setState({drawing: false});
			}
		}
	}

	removeAllLayers() {
		if (!this.editControlRef.current)
			return;
		const layerContainer = this.editControlRef.current.leafletElement.options.edit.featureGroup;
		const layers = layerContainer._layers;
		Object.keys(layers).forEach(id => {
			const layer = layers[id];
			layerContainer.removeLayer(layer);
		});
		// this.setState({disabled: this.props.disabled});
	}

	render() {

		return (
			<Row>
				<Col className="pt-0">
					{ this.props.label &&
						<Label className="mb-0">{this.props.label}</Label>
					}
					<LeafletMap
						style={{width: 100 + '%', height: this.props.height + 'px'}}
						center={this.props.center}
						zoom={this.props.zoom || 10}
					>
						<FeatureGroup ref={this._onFeatureGroupReady}>
							{ !this.state.disabled &&
								<EditControl
									ref={this.editControlRef}
									position="topright"
									draw={this.props.draw || {}}
									edit={{
										remove: true,
										edit: this.props.isEditEnabled===false ? false : {
											selectedPathOptions: {editing: true}
										},
									}}
									onEdited={this._onEdited}
									onCreated={this._onCreated}
									onDeleted={this._onDeleted}
									onDrawStart={() => this.setState({drawing: true})}
								/>
							}
						</FeatureGroup>
						<TileLayer
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
						/>
					</LeafletMap>
				</Col>
			</Row>
		);
	}

	_editableFG = null;

	_onFeatureGroupReady = (reactFGref) => {

		// populate the leaflet FeatureGroup with the geoJson layers
		if (!reactFGref)
			return;

		// store the ref for future access to content

		this._editableFG = reactFGref;
		this._drawGeoJSON(reactFGref);
	}

	_drawGeoJSON = (reactFGref) => {
		const { geojson } = this.props;
		if (!geojson || geojson === '' || (Array.isArray(geojson) && geojson.length === 0))
			return;

		let leafletGeoJSON = new L.GeoJSON(geojson);
		let leafletFG = reactFGref.leafletElement;

		leafletGeoJSON.eachLayer( (layer) => {
			leafletFG.addLayer(layer);
		});

		const bounds = leafletGeoJSON.getBounds();
		if (Object.keys(bounds).length !== 0)
			leafletFG._map.flyToBounds(bounds);
	}
}

PolygonInput.propTypes = {
	center: PropTypes.array.isRequired,
	height: PropTypes.number.isRequired,
	zoom: PropTypes.number,
	draw: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	polygon: PropTypes.array,
	geojson: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.string,
	]),
	disabled: PropTypes.bool,
	isEditEnabled: PropTypes.bool,
};

PolygonInput.defaultProps = {
	isEditEnabled: true,
}

export default PolygonInput;
