import * as roles from 'core/model/roles';

const Pec = '/pec';

export const StaticRoutes = {
	Pec,
};

const routes = [
	{
		url: Pec,
		name: 'pec',
		component: 'Pec',
		role: roles.REVIEWER,
	},
];

const combined = {StaticRoutes, routes};

export default combined;
