// Actions
const CREATE_BREADCRUMB = 'CREATE_BREADCRUMB';
const ADD_ITEM = 'ADD_BREADCRUMB_ITEM';

// Reducer
const initialState = {
	items: {},
};

export default (state = initialState, action) => {
	switch (action.type) {

		case CREATE_BREADCRUMB:
			return {
				...state,
				items: action.items,
			}

		case ADD_ITEM:
			return {
				...state,
				items: {...state.items, ...action.item}
			}

		default:
	  		return state;
  	}
};

// Action creators


export const createBreadcrumb = (items) => ({
	type: CREATE_BREADCRUMB,
	items
});

export const addBreadcrumbItem = (item) => ({
	type: ADD_ITEM,
	item
})
