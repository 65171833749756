import React, { Component } from 'react';
import Tooltip from 'react-tooltip';

import { Table, Thead, Tbody, Tr, Td } from 'css-table';
import T from 'modules/i18n';

class Info extends Component {
	render() {

		const { data } = this.props;

		return (
			<Table>
				<Thead>
					<Tr>
						<Td><T>description</T></Td>
						<Td><T>unit</T></Td>
						<Td><T>sensor</T></Td>
						<Td><T>property</T></Td>
					</Tr>
				</Thead>
				<Tbody>
					{ data.map(stream => (
						<Tr key={`row_${stream.token}`}>
							<Td>
								{stream.description}
							</Td>
							<Td>
								<span data-tip data-for={`unit_${stream.token}`}>{stream.unit.symbol}</span>
								<Tooltip id={`unit_${stream.token}`} delayShow={500} delayUpdate={5000}>
									<p>{`${stream.unit.name} (${stream.unit.symbol})`}</p>
								</Tooltip>
							</Td>
							<Td>
								<span data-tip data-for={`sensor_${stream.token}`}>{stream.sensor.label}</span>
								<Tooltip id={`sensor_${stream.token}`} delayShow={500} delayUpdate={5000}>
									<dl>
										<dt><T>label</T></dt><dd>{stream.sensor.label}</dd>
										<dt><T>description</T></dt><dd><ul>{
											Object.keys(stream.sensor.description).map(key => (
												<li key={`description_${key}`}>{key}: <em>{stream.sensor.description[key]}</em></li>
											))
										}</ul></dd>
										<dt><T>reference</T></dt><dd>{stream.sensor.reference}</dd>
									</dl>
								</Tooltip>
							</Td>
							<Td>
								<span data-tip data-for={`property_${stream.token}`}>{stream.property.symbol || stream.property.name}</span>
								<Tooltip id={`property_${stream.token}`} delayShow={500} delayUpdate={5000}>
									<dl>
										<dt><T>name</T></dt><dd>{stream.property.name}</dd>
										<dt><T>symbol</T></dt><dd>{stream.property.symbol}</dd>
										<dt><T>definition</T></dt><dd><a href={stream.property.definition}>{stream.property.definition}</a></dd>
										<dt><T>description</T></dt><dd>{stream.property.description}</dd>
									</dl>
								</Tooltip>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		);
	}
}

export default Info;