import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Card } from 'reactstrap';

import { StaticRoutes } from '../model/routes';
import { SecureContent } from 'core/components';
import * as roles from 'core/model/roles';
import { ReactComponent as AlertSVG } from '../img/alert.svg';
import T from 'modules/i18n';

class Alerts extends Component {

	render() {

		return (
			<SecureContent role={roles.REVIEWER}>
				<Card className="dashboard-card">
					<span className="title"><T>alert</T></span>
					<Link to={StaticRoutes.Alerts} title={this.props.intl.messages['alert']}>
						<AlertSVG/>
					</Link>
				</Card>
			</SecureContent>
		);
	}

}

export const DashboardAlert = injectIntl(Alerts)
